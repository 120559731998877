// const message = i18n.t("m.test");
import i18n from "@/i18n";

export const event_block = [
    {
        "type": "EVENT_NOW",
        "tooltip": i18n.t("event.event_now_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("event.event_now_message"),
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "event",
        "colour": 45
    },
    {
        "type": "EVENT_SET_CANCELLED",
        "tooltip": i18n.t("event.event_set_cancelled_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("event.event_set_cancelled_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "event",
                "check": "event"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 45
    }, {
        "type": "EVENT_GET_LOCALE",
        "tooltip": i18n.t("event.event_get_locale_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("event.event_get_locale_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "event"
            }
        ],
        "output": "string",
        "colour": 45
    },
]
