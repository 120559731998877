import i18n from "@/i18n";

export const data_source_block = [
    {
        "type": "DATA_SOURCE_PUT_CACHE",
        "tooltip": i18n.t("data_source.put_cache_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.put_cache_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_GET_CACHE",
        "tooltip": i18n.t("data_source.get_cache_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.get_cache_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": null,
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_REMOVE_CACHE",
        "tooltip": i18n.t("data_source.remove_cache_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.remove_cache_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_PUT_DATA_BASE",
        "tooltip": i18n.t("data_source.put_data_base_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.put_data_base_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "number",
                    "string",
                    "boolean_block",
                    "location",
                    "world",
                    "bossbar"
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_GET_DATA_BASE",
        "tooltip": i18n.t("data_source.get_data_base_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.get_data_base_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": null,
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_REMOVE_DATA_BASE",
        "tooltip": i18n.t("data_source.remove_data_base_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.remove_data_base_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_CONTAINS_KEY_CACHE",
        "tooltip": i18n.t("data_source.contains_key_cache_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.contains_key_cache_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_CONTAINS_KEY_DATABASE",
        "tooltip": i18n.t("data_source.contains_key_database_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.contains_key_database_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_GET_STRING_FROM_CONFIG",
        "tooltip": i18n.t("data_source.get_string_from_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.get_string_from_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "string",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_GET_LONG_FROM_CONFIG",
        "tooltip": i18n.t("data_source.get_long_from_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.get_long_from_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "number",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_GET_DOUBLE_FROM_CONFIG",
        "tooltip": i18n.t("data_source.get_double_from_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.get_double_from_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "number",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_GET_BOOLEAN_FROM_CONFIG",
        "tooltip": i18n.t("data_source.get_boolean_from_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.get_boolean_from_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_PUT_STRING_2_CONFIG",
        "tooltip": i18n.t("data_source.put_string_2_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.put_string_2_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "string",
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_PUT_LONG_2_CONFIG",
        "tooltip": i18n.t("data_source.put_long_2_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.put_long_2_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "number",
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_PUT_DOUBLE_2_CONFIG",
        "tooltip": i18n.t("data_source.put_double_2_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.put_double_2_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "number",
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_PUT_BOOLEAN_2_CONFIG",
        "tooltip": i18n.t("data_source.put_boolean_2_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.put_boolean_2_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": [
                    "boolean_block",
                ]
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 30
    },
    {
        "type": "DATA_SOURCE_GET_STRING_LIST_FROM_CONFIG",
        "tooltip": i18n.t("data_source.get_string_list_from_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.get_string_list_from_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "list",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_GET_LONG_LIST_FROM_CONFIG",
        "tooltip": i18n.t("data_source.get_long_list_from_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.get_long_list_from_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            }
        ],
        "output": "list",
        "colour": 30
    }, {
        "type": "DATA_SOURCE_GET_DOUBLE_LIST_FROM_CONFIG",
        "tooltip": i18n.t("data_source.get_double_list_from_config_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("data_source.get_double_list_from_config_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            }
        ],
        "output": "list",
        "colour": 30
    },



]