import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


/**
 * 常量路由
 */
export const routes = [
    {
        path: '/',
        component: () => import("@/pages/home/index.vue"),
    },
    {
        path: '/pages',
        component: () => import("@/pages/pages/index"),
    },
    {
        path: '/workspace',
        component: () => import("@/pages/workspace/index"),
    },
    {
        path: '/backpack',
        component: () => import("@/pages/backpack/index"),
    },

    {
        path: '/qq/auth/callback',
        name: 'login',
        component: () => import("@/pages/home/index.vue"),
    },
    {
        path: '/wiki',
        component: () => import("@/pages/wiki/index.vue"),
    }
]


const router = new Router({
    mode: 'history',
    routes
})
//
export default router
