import i18n from "@/i18n";

export const location_block = [
    {
        "type": "LOCATION_CREATE_1",
        "tooltip": i18n.t("location.create_1_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.create_1_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "X",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "check": "number"
            }
        ],
        "output": "location",
        "colour": 195,
        "inputsInline": true
    },
    {
        "type": "LOCATION_CREATE_2",
        "tooltip": i18n.t("location.create_2_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.create_2_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "X",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "yaw",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "pitch",
                "check": "number"
            }
        ],
        "output": "location",
        "colour": 195,
        "inputsInline": true
    },
    {
        "type": "LOCATION_CREATE_3",
        "tooltip": i18n.t("location.create_3_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.create_3_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "world"
            },
            {
                "type": "input_value",
                "name": "X",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "check": "number"
            }
        ],
        "output": "location",
        "colour": 195,
        "inputsInline": true
    },
    {
        "type": "LOCATION_CREATE_4",
        "tooltip": i18n.t("location.create_4_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.create_4_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "world"
            },
            {
                "type": "input_value",
                "name": "X",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "yaw",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "pitch",
                "check": "number"
            }
        ],
        "output": "location",
        "colour": 195,
        "inputsInline": true
    },
    {
        "type": "LOCATION_GET_X",
        "tooltip": i18n.t("location.get_x_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.get_x_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    },
    {
        "type": "LOCATION_GET_Y",
        "tooltip": i18n.t("location.get_y_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.get_y_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    },
    {
        "type": "LOCATION_GET_Z",
        "tooltip": i18n.t("location.get_z_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.get_z_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    },
    {
        "type": "LOCATION_GET_YAW",
        "tooltip": i18n.t("location.get_yaw_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.get_yaw_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    },
    {
        "type": "LOCATION_GET_PITCH",
        "tooltip": i18n.t("location.get_pitch_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.get_pitch_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "number",
        "colour": 195
    },
    {
        "type": "LOCATION_GET_TO",
        "tooltip": i18n.t("location.get_to_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.get_to_message0"),
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "location",
        "colour": 195
    },
    {
        "type": "LOCATION_GET_FROM",
        "tooltip": i18n.t("location.get_from_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.get_from_message0"),
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "location",
        "colour": 195
    },
    {
        "type": "LOCATION_GET_BLOCK",
        "tooltip": i18n.t("location.get_block_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("location.get_block_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "block_block"
            }
        ],
        "output": "location",
        "colour": 195
    }




]