import {data_source_contents} from "@/data/customize/category/contents/data_source_contents";

export const data_source_category = [
    {
        "kind": "category",
        "name": "数据源",
        "colour": "#A5805B",
        "contents": [
            ...data_source_contents
        ]
    }
]