export const message_contents = [
    {
        "kind": "block",
        "type": "MESSAGE_PLAYER_SEND_MESSAGE",
        "name": "给一个玩家发送消息"
    }, {
        "kind": "block",
        "type": "MESSAGE_PLAYER_SEND_MESSAGE_ALL",
        "name": "给一个全部玩家发送消息"
    }, {
        "kind": "block",
        "type": "MESSAGE_SET_JOIN_MESSAGE",
        "name": "设置加入服务器消息"
    }, {
        "kind": "block",
        "type": "MESSAGE_SET_QUIT_MESSAGE",
        "name": "设置退出服务器消息"
    }, {
        "kind": "block",
        "type": "MESSAGE_SET_CHAT_FORMAT",
        "name": "设置聊天格式"
    }, {
        "kind": "block",
        "type": "MESSAGE_GET_CHAT_MESSAGE",
        "name": "设置聊天格式"
    }, {
        "kind": "block",
        "type": "MESSAGE_GET_COMMAND",
        "name": "获取聊天内容"
    }, {
        "kind": "block",
        "type": "MESSAGE_GET_PAPI_RESULT",
        "name": "获得papi变量结果"
    }, {
        "kind": "block",
        "type": "MESSAGE_GET_PAPI_RESULT_PLAYER",
        "name": "获得papi变量结果"
    },
]
