export const server_contents = [
    {
        "kind": "block",
        "type": "SERVER_SET_MOTD",
        "name": "设置服务器MOTD"
    }, {
        "kind": "block",
        "type": "SERVER_DISPATCH_COMMAND",
        "name": "让控制台强制执行一个指令"
    },
    {
        "kind": "block",
        "type": "SERVER_GET_ONLINE_PLAYERS_SIZE",
        "name": "获取当前在线玩家数量"
    },

]