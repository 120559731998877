import i18n from "@/i18n";

export const team_block = [
    {
        "type": "TEAM_CREATE_TEAM",
        "tooltip": i18n.t("team.create_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.create_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "team",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_CREATE_TEAM_SCOREBOARD",
        "tooltip": i18n.t("team.create_team_scoreboard_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.create_team_scoreboard_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "scoreboard"
            }, {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "team",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_GET_TEAM",
        "tooltip": i18n.t("team.get_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "team",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_GET_TEAM_SCOREBOARD",
        "tooltip": i18n.t("team.get_team_scoreboard_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_team_scoreboard_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "scoreboard"
            }, {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "team",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_GET_PLAYER_TEAM",
        "tooltip": i18n.t("team.get_player_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_player_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "team",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_GET_PLAYER_TEAM_SCOREBOARD",
        "tooltip": i18n.t("team.get_player_team_scoreboard_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_player_team_scoreboard_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "scoreboard"
            }, {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "PLAYER"
            }
        ],
        "output": "team",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_ADD_PLAYER_2_TEAM",
        "tooltip": i18n.t("team.add_player_2_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.add_player_2_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }, {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_ADD_PLAYER_2_TEAM_TEAM",
        "tooltip": i18n.t("team.add_player_2_team_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.add_player_2_team_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }, {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "team"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_IS_PLAYER_IN_TEAM",
        "tooltip": i18n.t("team.is_player_in_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.is_player_in_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }, {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_IS_PLAYER_IN_TEAM_TEAM",
        "tooltip": i18n.t("team.is_player_in_team_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.is_player_in_team_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }, {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "team"
            }
        ],
        "output": "boolean_block",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_REMOVE_PLAYER_4_TEAM",
        "tooltip": i18n.t("team.remove_player_4_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.remove_player_4_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }, {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_REMOVE_PLAYER_4_TEAM_TEAM",
        "tooltip": i18n.t("team.remove_player_4_team_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.remove_player_4_team_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "team"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_DELETE_TEAM",
        "tooltip": i18n.t("team.delete_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.delete_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_DELETE_TEAM_TEAM",
        "tooltip": i18n.t("team.delete_team_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.delete_team_team_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team",
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_GET_COLOR",
        "tooltip": i18n.t("team.get_color_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_color_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team",
            }
        ],
        "output": "string",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_GET_ENTRIES",
        "tooltip": i18n.t("team.get_entries_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_entries_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            }
        ],
        "output": "list",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_GET_DISPLAY_NAME",
        "tooltip": i18n.t("team.get_display_name_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_display_name_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            }
        ],
        "output": "string",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_GET_NAME_TAG_VISIBILITY",
        "tooltip": i18n.t("team.get_name_tag_visibility_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_name_tag_visibility_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            }
        ],
        "output": "string",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_GET_NAME",
        "tooltip": i18n.t("team.get_name_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_name_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            }
        ],
        "output": "string",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_GET_PLAYER",
        "tooltip": i18n.t("team.get_player_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_player_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            }
        ],
        "output": "list",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_GET_PREFIX",
        "tooltip": i18n.t("team.get_prefix_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_prefix_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            }
        ],
        "output": "string",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_GET_SUFFIX",
        "tooltip": i18n.t("team.get_suffix_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_suffix_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            }
        ],
        "output": "string",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_GET_SCOREBOARD",
        "tooltip": i18n.t("team.get_scoreboard_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_scoreboard_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            }
        ],
        "output": "scoreboard",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_GET_SIZE",
        "tooltip": i18n.t("team.get_size_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.get_size_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            }
        ],
        "output": "number",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_SET_ALLOW_FRIENDLY_FIRE",
        "tooltip": i18n.t("team.set_allow_friendly_fire_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.set_allow_friendly_fire_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    }, {
        "type": "TEAM_SET_CAN_SEE_FRIENDLY_INVISIBLE",
        "tooltip": i18n.t("team.set_can_see_friendly_invisible_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.set_can_see_friendly_invisible_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_SET_COLOR",
        "tooltip": i18n.t("team.set_color_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.set_color_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_SET_DISPLAY_NAME",
        "tooltip": i18n.t("team.set_display_name_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.set_display_name_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_SET_NAME_TAG_VISIBILITY",
        "tooltip": i18n.t("team.set_name_tag_visibility_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.set_name_tag_visibility_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_SET_PREFIX",
        "tooltip": i18n.t("team.set_prefix_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.set_prefix_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    },
    {
        "type": "TEAM_SET_SUFFIX",
        "tooltip": i18n.t("team.set_suffix_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("team.set_suffix_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "team"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": '#93A55B'
    }
]