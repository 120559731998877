import i18n from "@/i18n";

export const server_block = [
    {
        "type": "SERVER_SET_MOTD",
        "tooltip": i18n.t("server.set_motd_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("server.set_motd_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "event"
            },
            {
                "type": "input_value",
                "name": "line1",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "line2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 315
    },
    {
        "type": "SERVER_DISPATCH_COMMAND",
        "tooltip": i18n.t("server.dispatch_command_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("server.dispatch_command_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 315
    },
    {
        "type": "SERVER_GET_ONLINE_PLAYERS_SIZE",
        "tooltip": i18n.t("server.get_online_players_size_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("server.get_online_players_size_message0"),
        "args0": [],
        "output": "number",
        "colour": 315
    }

]