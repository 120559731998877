import i18n from "@/i18n";

export const base_block = [
    {
        "type": "BASE_SYMBOL",
        "tooltip": i18n.t("base.symbol_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.symbol_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "1"
            },
            {
                "type": "field_dropdown",
                "name": "4",
                "options": [
                    [i18n.t("base.options.equal"), "=="],
                    [i18n.t("base.options.less_than"), "<"],
                    [i18n.t("base.options.greater_than"), ">"],
                    [i18n.t("base.options.less_than_or_equal"), "<="],
                    [i18n.t("base.options.greater_than_or_equal"), ">="]
                ]
            },
            {
                "type": "input_dummy",
                "name": "2"
            },
            {
                "type": "input_value",
                "name": "3"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    },
    {
        "type": "BASE_NUMBER",
        "tooltip": i18n.t("base.number_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.number_message"),
        "args0": [
            {
                "type": "field_number",
                "name": "NUMBER",
                "value": 0
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "number",
        "colour": 15
    },
    {
        "type": "BASE_STRING",
        "tooltip": i18n.t("base.string_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.string_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "STRING_NAME",
                "text": ""
            }
        ],
        "output": "string",
        "colour": 15
    }
    ,
    {
        "type": "BASE_TO_STRING",
        "tooltip": i18n.t("base.to_string_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.to_string_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            }
        ],
        "output": "string",
        "colour": 15
    },
    {
        "type": "BASE_BOOLEAN",
        "tooltip": i18n.t("base.boolean_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.boolean_message"),
        "args0": [
            {
                "type": "field_dropdown",
                "name": "BOOLEAN",
                "options": [
                    [i18n.t("base.options.true"), "true"],
                    [i18n.t("base.options.false"), "false"]
                ]
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_CONCAT_STRING",
        "tooltip": i18n.t("base.concat_string_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.concat_string_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "string",
        "colour": 15
    },
    {
        "type": "BASE_NOT",
        "tooltip": i18n.t("base.not_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.not_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "boolean_block"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_GENERATE_UUID",
        "tooltip": i18n.t("base.generate_uuid_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.generate_uuid_message"),
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 15
    }, {
        "type": "BASE_SUB_STRING_1",
        "tooltip": i18n.t("base.sub_string_1_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.sub_string_1_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "start",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": "string",
        "colour": 15,
        "inputsInline": false
    }, {
        "type": "BASE_CONTAINS_STRING",
        "tooltip": i18n.t("base.contains_string_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.contains_string_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "start",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 15,
        "inputsInline": false
    },
    {
        "type": "BASE_SUB_STRING_2",
        "tooltip": i18n.t("base.sub_string_2_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.sub_string_2_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "start",
                "align": "RIGHT",
                "check": "number"
            }, {
                "type": "input_value",
                "name": "start2",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": "string",
        "colour": 15,
        "inputsInline": false
    }, {
        "type": "BASE_REPLACE_STRING",
        "tooltip": i18n.t("base.replace_string_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.replace_string_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "start",
                "align": "RIGHT",
                "check": "string"
            }, {
                "type": "input_value",
                "name": "start2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "string",
        "colour": 15,
        "inputsInline": false
    }, {
        "type": "BASE_INSTANCE_OF",
        "tooltip": i18n.t("base.instance_of_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.instance_of_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME"
            },
            {
                "type": "input_value",
                "name": "start",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 15,
        "inputsInline": false
    }, {
        "type": "BASE_STRUCT_TYPE",
        "tooltip": i18n.t("base.struct_type_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.struct_type_message"),
        "args0": [
            {
                "type": "field_dropdown",
                "name": "BOOLEAN",
                "options": [
                    [
                        i18n.t("base.options.long"),
                        "LONG"
                    ],
                    [
                        i18n.t("base.options.double"),
                        "DOUBLE"
                    ],
                    [
                        i18n.t("base.options.string"),
                        "STRING"
                    ],
                    [
                        i18n.t("base.options.boolean"),
                        "BOOLEAN"
                    ],
                    [
                        i18n.t("base.options.list"),
                        "LIST"
                    ],
                    [
                        i18n.t("base.options.map"),
                        "MAP"
                    ],
                    [
                        i18n.t("base.options.player"),
                        "PLAYER"
                    ],
                    [
                        i18n.t("base.options.location"),
                        "LOCATION"
                    ],
                    [
                        i18n.t("base.options.world"),
                        "WORLD"
                    ],
                    [
                        i18n.t("base.options.bossBar"),
                        "BOSSBAR"
                    ],
                    [
                        i18n.t("base.options.scoreboard"),
                        "SCOREBOARD"
                    ],
                    [
                        i18n.t("base.options.team"),
                        "TEAM"
                    ],
                    [
                        i18n.t("base.options.block"),
                        "BLOCK"
                    ]
                ]
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 15
    }, {
        "type": "BASE_GET_STRUCT_TYPE",
        "tooltip": i18n.t("base.get_struct_type_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.get_struct_type_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 15
    }, {
        "type": "BASE_IS_NULL",
        "tooltip": i18n.t("base.base_is_null_tooltip"),  // "可以传递任何一个内容，判断这个内容是否为空"
        "helpUrl": "",
        "message0": i18n.t("base.base_is_null_message"),  // "判断值是否为空 值： %1"
        "args0": [
            {
                "type": "input_value",
                "name": "NAME"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_OPERATE",
        "tooltip": i18n.t("base.base_operate_tooltip"),  // "四则运算，可以对数值加减乘除"
        "helpUrl": "",
        "message0": i18n.t("base.base_operate_message"),  // "数字： %1 %2 %3 数字： %4"
        "args0": [
            {
                "type": "input_value",
                "name": "NUM1",
                "check": "number"
            },
            {
                "type": "field_dropdown",
                "name": "OPERATOR",
                "options": [
                    [
                        i18n.t("base.options.add"),  // "加"
                        "+"
                    ],
                    [
                        i18n.t("base.options.subtract"),  // "减"
                        "-"
                    ],
                    [
                        i18n.t("base.options.multiply"),  // "乘"
                        "*"
                    ],
                    [
                        i18n.t("base.options.divide"),  // "除"
                        "/"
                    ]
                ]
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            },
            {
                "type": "input_value",
                "name": "NUM2",
                "check": "number"
            }
        ],
        "output": "number",
        "colour": 15
    }, {
        "type": "BASE_ABS",
        "tooltip": i18n.t("base.base_abs_tooltip"),  // "求数值绝对值"
        "helpUrl": "",
        "message0": i18n.t("base.base_abs_message"),  // "求数值绝对值 值： %1"
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            }
        ],
        "output": "number",
        "colour": 15
    }, {
        "type": "BASE_REMAINDER",
        "tooltip": i18n.t("base.base_remainder_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_remainder_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "start",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": "number",
        "colour": 15
    },
    {
        "type": "BASE_RANDOM_NUMBER",
        "tooltip": i18n.t("base.base_random_number_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_random_number_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            }
        ],
        "output": "number",
        "colour": 15
    }, {
        "type": "BASE_AND_OR",
        "tooltip": i18n.t("base.base_and_or_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_and_or_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NUM1",
                "check": "boolean_block"
            },
            {
                "type": "field_dropdown",
                "name": "NAME",
                "options": [
                    [i18n.t("base.options.and"), "&&"],
                    [i18n.t("base.options.or"), "||"]
                ]
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            },
            {
                "type": "input_value",
                "name": "NUM2",
                "check": "boolean_block"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_CREATE_LIST",
        "tooltip": i18n.t("base.base_create_list_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_create_list_message"),
        "args0": [],
        "output": "list",
        "colour": 15
    }, {
        "type": "BASE_LIST_ADD",
        "tooltip": i18n.t("base.base_list_add_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_list_add_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "list"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 15
    }, {
        "type": "BASE_LIST_REMOVE_ELEMENT",
        "tooltip": i18n.t("base.base_list_remove_element_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_list_remove_element_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "list"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 15
    }, {
        "type": "BASE_LIST_REMOVE_INDEX",
        "tooltip": i18n.t("base.base_list_remove_index_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_list_remove_index_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "list"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 15
    }, {
        "type": "BASE_LIST_CONTAINS",
        "tooltip": i18n.t("base.base_list_contains_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_list_contains_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "list"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_LIST_GET",
        "tooltip": i18n.t("base.base_list_get_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_list_get_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "list"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": null,
        "colour": 15
    }, {
        "type": "BASE_LIST_IS_EMPTY",
        "tooltip": i18n.t("base.base_list_is_empty_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_list_is_empty_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "list"
            }
        ],
        "output": "boolean_block",
        "colour": 15
    }, {
        "type": "BASE_LIST_CLEAR",
        "tooltip": i18n.t("base.base_list_clear_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_list_clear_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "list"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 15
    }, {
        "type": "BASE_LIST_SIZE",
        "tooltip": i18n.t("base.base_list_size_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_list_size_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "list"
            }
        ],
        "output": "number",
        "colour": 15
    }, {
        "type": "BASE_CREATE_MAP",
        "tooltip": i18n.t("base.base_create_map_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_create_map_message"),
        "args0": [],
        "output": "map",
        "colour": 15
    }, {
        "type": "BASE_MAP_PUT_NUM",
        "tooltip": i18n.t("base.base_map_put_num_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_put_num_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "value2",
                "align": "RIGHT",
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "inputsInline": false,
        "colour": 15
    }, {
        "type": "BASE_MAP_PUT_STR",
        "tooltip": i18n.t("base.base_map_put_str_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_put_str_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "value2",
                "align": "RIGHT",
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "inputsInline": false,
        "colour": 15
    }, {
        "type": "BASE_MAP_REMOVE_NUM",
        "tooltip": i18n.t("base.base_map_remove_num_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_remove_num_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "inputsInline": false,
        "colour": 15
    }, {
        "type": "BASE_MAP_REMOVE_STR",
        "tooltip": i18n.t("base.base_map_remove_str_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_remove_str_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "inputsInline": false,
        "colour": 15
    }, {
        "type": "BASE_MAP_GET_NUM",
        "tooltip": i18n.t("base.base_map_get_num_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_get_num_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": null,
        "colour": 15
    },
    {
        "type": "BASE_MAP_GET_STR",
        "tooltip": i18n.t("base.base_map_get_str_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_get_str_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": null,
        "colour": 15
    }, {
        "type": "BASE_MAP_CONTAINS_KEY_NUM",
        "tooltip": i18n.t("base.base_map_contains_key_num_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_contains_key_num_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": 'boolean_block',
        "colour": 15
    }, {
        "type": "BASE_MAP_CONTAINS_KEY_STR",
        "tooltip": i18n.t("base.base_map_contains_key_str_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_contains_key_str_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            },
            {
                "type": "input_value",
                "name": "value",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": 'boolean_block',
        "colour": 15
    }, {
        "type": "BASE_MAP_CLEAR",
        "tooltip": i18n.t("base.base_map_clear_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_clear_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 15
    }, {
        "type": "BASE_MAP_KEY_SET",
        "tooltip": i18n.t("base.base_map_key_set_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_key_set_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            }
        ],
        "output": 'list',
        "colour": 15
    }, {
        "type": "BASE_MAP_VALUES",
        "tooltip": i18n.t("base.base_map_values_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_values_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            }
        ],
        "output": 'list',
        "colour": 15
    }, {
        "type": "BASE_MAP_IS_EMPTY",
        "tooltip": i18n.t("base.base_map_is_empty_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_is_empty_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            }
        ],
        "output": 'boolean_block',
        "colour": 15
    }, {
        "type": "BASE_MAP_SIZE",
        "tooltip": i18n.t("base.base_map_size_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_map_size_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "map"
            }
        ],
        "output": 'number',
        "colour": 15
    }, {
        "type": "BASE_SET_PAPI_RESULT",
        "tooltip": i18n.t("base.base_set_papi_result_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_set_papi_result_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 15
    }, {
        "type": "BASE_RETURN_1",
        "tooltip": i18n.t("base.base_return_1_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_return_1_message"),
        "args0": [],
        "previousStatement": "flow",
        "colour": 15
    }, {
        "type": "BASE_BREAK",
        "tooltip": i18n.t("base.base_break_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_break_message"),
        "args0": [],
        "previousStatement": "flow",
        "colour": 15
    }, {
        "type": "BASE_CONTINUE",
        "tooltip": i18n.t("base.base_continue_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("base.base_continue_message"),
        "args0": [],
        "previousStatement": "flow",
        "colour": 15
    }


]
