import i18n from "@/i18n";

export const scoreboard_block = [
    {
        "type": "SCOREBOARD_CREATE",
        "tooltip": i18n.t("scoreboard.create_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("scoreboard.create_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "lines",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "scoreboard",
        "colour": 270
    },
    {
        "type": "SCOREBOARD_CREATE_LIST",
        "tooltip": i18n.t("scoreboard.create_list_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("scoreboard.create_list_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "lines",
                "align": "RIGHT",
                "check": "list"
            }
        ],
        "output": "scoreboard",
        "colour": 270
    },
    {
        "type": "SCOREBOARD_SET_SCOREBOARD",
        "tooltip": i18n.t("scoreboard.set_scoreboard_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("scoreboard.set_scoreboard_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "SCOREBOARD",
                "align": "RIGHT",
                "check": "scoreboard"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 270
    },
    {
        "type": "SCOREBOARD_RESET_SCOREBOARD",
        "tooltip": i18n.t("scoreboard.reset_scoreboard_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("scoreboard.reset_scoreboard_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 270
    }

]