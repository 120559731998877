export const base_contents = [
    {
        "kind": "block",
        "type": "BASE_SYMBOL",
        "name": "基本块判断"
    },
    {
        "kind": "block",
        "type": "BASE_NUMBER",
        "name": "基本块数字"
    },
    {
        "kind": "block",
        "type": "BASE_STRING",
        "name": "基本块字符串"
    },
    {
        "kind": "block",
        "type": "BASE_BOOLEAN",
        "name": "基本块布尔"
    },
    {
        "kind": "block",
        "type": "BASE_TO_STRING",
        "name": "转换成字符串"
    }, {
        "kind": "block",
        "type": "BASE_CONCAT_STRING",
        "name": "字符串拼接"
    }, {
        "kind": "block",
        "type": "BASE_NOT",
        "name": "取反"
    }, {
        "kind": "block",
        "type": "BASE_AND_OR",
        "name": "与 或"
    }, {
        "kind": "block",
        "type": "BASE_GENERATE_UUID",
        "name": "生成一串随机的UUID字符串"
    }, {
        "kind": "block",
        "type": "BASE_SUB_STRING_1",
        "name": "截取字符串"
    }, {
        "kind": "block",
        "type": "BASE_SUB_STRING_2",
        "name": "截取字符串"
    }, {
        "kind": "block",
        "type": "BASE_CONTAINS_STRING",
        "name": "判断字符串A是否存在字符串B"
    }, {
        "kind": "block",
        "type": "BASE_REPLACE_STRING",
        "name": "将字符串A里面的字符串B内容替换为字符串C"
    }, {
        "kind": "block",
        "type": "BASE_INSTANCE_OF",
        "name": "判断数据类型是否为"
    }, {
        "kind": "block",
        "type": "BASE_STRUCT_TYPE",
        "name": "数据类型"
    }, {
        "kind": "block",
        "type": "BASE_GET_STRUCT_TYPE",
        "name": "获取数据类型"
    }, {
        "kind": "block",
        "type": "BASE_IS_NULL",
        "name": "判断值是否为空"
    }, {
        "kind": "block",
        "type": "BASE_OPERATE",
        "name": "四则运算"
    }, {
        "kind": "block",
        "type": "BASE_ABS",
        "name": "求数值绝对值"
    }, {
        "kind": "block",
        "type": "BASE_REMAINDER",
        "name": "求余"
    }, {
        "kind": "block",
        "type": "BASE_RANDOM_NUMBER",
        "name": "取一个范围内的随机数"
    }, {
        "kind": "block",
        "type": "BASE_CREATE_LIST",
        "name": "创建一个空List集合"
    }, {
        "kind": "block",
        "type": "BASE_LIST_ADD",
        "name": "往List集合中添加一个元素"
    }, {
        "kind": "block",
        "type": "BASE_LIST_REMOVE_ELEMENT",
        "name": "从List集合中删除一个元素"
    }, {
        "kind": "block",
        "type": "BASE_LIST_REMOVE_INDEX",
        "name": "从List集合中删除第几个元素"
    }, {
        "kind": "block",
        "type": "BASE_LIST_CONTAINS",
        "name": "判断List集合中是否存在某个元素"
    }, {
        "kind": "block",
        "type": "BASE_LIST_GET",
        "name": "从List集合中获取第几个元素"
    }, {
        "kind": "block",
        "type": "BASE_LIST_IS_EMPTY",
        "name": "判断List集合是否为空"
    }, {
        "kind": "block",
        "type": "BASE_LIST_CLEAR",
        "name": "将List集合清空"
    }, {
        "kind": "block",
        "type": "BASE_LIST_SIZE",
        "name": "获取List集合的大小"
    }, {
        "kind": "block",
        "type": "BASE_CREATE_MAP",
        "name": "创建一个空Map集合"
    }, {
        "kind": "block",
        "type": "BASE_MAP_PUT_NUM",
        "name": "往Map中添加一条数据(数字为键)"
    }, {
        "kind": "block",
        "type": "BASE_MAP_PUT_STR",
        "name": "往Map中添加一条数据(字符串为键)"
    }, {
        "kind": "block",
        "type": "BASE_MAP_REMOVE_NUM",
        "name": "从Map中删除一条数据(数字为键)"
    }, {
        "kind": "block",
        "type": "BASE_MAP_REMOVE_STR",
        "name": "从Map中删除一条数据(字符串为键)"
    }, {
        "kind": "block",
        "type": "BASE_MAP_GET_NUM",
        "name": "从Map中获取一条数据(数字为键)"
    }, {
        "kind": "block",
        "type": "BASE_MAP_GET_STR",
        "name": "从Map中获取一条数据(数字为键)"
    }, {
        "kind": "block",
        "type": "BASE_MAP_CONTAINS_KEY_NUM",
        "name": "判断Map中是否存在某个键值(数字为键)"
    }, {
        "kind": "block",
        "type": "BASE_MAP_CONTAINS_KEY_STR",
        "name": "判断Map中是否存在某个键值(数字为键)"
    }, {
        "kind": "block",
        "type": "BASE_MAP_CLEAR",
        "name": "将Map清空"
    }, {
        "kind": "block",
        "type": "BASE_MAP_KEY_SET",
        "name": "获取Map中所有键值集合"
    }, {
        "kind": "block",
        "type": "BASE_MAP_VALUES",
        "name": "获取Map中所有结果值集合"
    }, {
        "kind": "block",
        "type": "BASE_MAP_IS_EMPTY",
        "name": "判断Map是否为空"
    }, {
        "kind": "block",
        "type": "BASE_MAP_SIZE",
        "name": "获取Map的大小"
    }, {
        "kind": "block",
        "type": "BASE_SET_PAPI_RESULT",
        "name": "设置PAPI占位符结果"
    }, {
        "kind": "block",
        "type": "BASE_RETURN_1",
        "name": "结束本次操作"
    }, {
        "kind": "block",
        "type": "BASE_BREAK",
        "name": "结束本次循环"
    }, {
        "kind": "block",
        "type": "BASE_CONTINUE",
        "name": "跳过本次循环"
    },
]
