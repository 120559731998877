import i18n from "@/i18n";

export const logical_control_block = [
    {
        "type": "LOGICAL_CONTROL_IF",
        "tooltip": i18n.t("logical_control.if_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("logical_control.if_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "IF0",
                "check": "boolean_block"
            },
            {
                "type": "input_statement",
                "name": "THEN",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165
    },
    {
        "type": "LOGICAL_CONTROL_IF_ELSE",
        "tooltip": i18n.t("logical_control.if_else_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("logical_control.if_else_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "IF",
                "check": "boolean_block"
            },
            {
                "type": "input_statement",
                "name": "THEN",
                "check": "flow"
            },
            {
                "type": "input_statement",
                "name": "ELSE",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165
    },
    {
        "type": "LOGICAL_CONTROL_REPEAT_DO",
        "tooltip": i18n.t("logical_control.repeat_do_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("logical_control.repeat_do_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            },
            {
                "type": "input_dummy",
                "name": "aaa"
            },
            {
                "type": "input_statement",
                "name": "DO",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165,
        "inputsInline": false
    },
    {
        "type": "LOGICAL_CONTROL_WHILE_DO",
        "tooltip": i18n.t("logical_control.while_do_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("logical_control.while_do_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "boolean_block"
            },
            {
                "type": "input_dummy",
                "name": "22"
            },
            {
                "type": "input_statement",
                "name": "DO",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165,
        "inputsInline": false
    },
    {
        "type": "LOGICAL_CONTROL_TIMER_1",
        "tooltip": i18n.t("logical_control.timer_1_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("logical_control.timer_1_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            },
            {
                "type": "input_dummy",
                "name": "222"
            },
            {
                "type": "input_statement",
                "name": "DO",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165,
        "inputsInline": false
    },
    {
        "type": "LOGICAL_CONTROL_TIMER_2",
        "tooltip": i18n.t("logical_control.timer_2_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("logical_control.timer_2_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_dummy",
                "name": "222"
            },
            {
                "type": "input_statement",
                "name": "DO",
                "check": "flow"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 165,
        "inputsInline": false
    }






]