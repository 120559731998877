import {javascriptGenerator, Order} from "blockly/javascript";

// 创建一条bossBar
javascriptGenerator.forBlock['BOSS_BAR_CREATE_BOSS_BAR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_color = generator.valueToCode(block, 'COLOR', Order.NONE);
    const value_tag = generator.valueToCode(block, 'TAG', Order.NONE);
    const value_number = generator.valueToCode(block, 'number', Order.NONE);

    const code = `#createBossbar(${value_name},${value_color},${value_tag},${value_number})`;
    return [code, Order.NONE];
}

// 添加bossBar
javascriptGenerator.forBlock['BOSS_BAR_ADD_BOSS_BAR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_bossbar = generator.valueToCode(block, 'bossbar', Order.NONE);

    const code = `#addBossbar(${value_name},${value_bossbar});`;
    return code;
}


// 移除bossBar
javascriptGenerator.forBlock['BOSS_BAR_REMOVE_BOSS_BAR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_bossbar = generator.valueToCode(block, 'BossBar', Order.NONE);

    const code = `#removeBossbar(${value_name},${value_bossbar});`;
    return code;
}

javascriptGenerator.forBlock['BOSS_BAR_SET_PROGRESS'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_num = generator.valueToCode(block, 'num', Order.NONE);

    const code = `#setProgress(${value_name},${value_num});`;
    return code;
}

javascriptGenerator.forBlock['BOSS_BAR_SET_VISIBLE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_boo = generator.valueToCode(block, 'boo', Order.NONE);

    const code = `#setVisible(${value_name},${value_boo});`;
    return code;
}

javascriptGenerator.forBlock['BOSS_BAR_SET_COLOR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_boo = generator.valueToCode(block, 'boo', Order.NONE);

    const code = `#setColor(${value_name},${value_boo});`;
    return code;
}

javascriptGenerator.forBlock['BOSS_BAR_SET_STYLE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_boo = generator.valueToCode(block, 'boo', Order.NONE);

    const code = `#setStyle(${value_name},${value_boo});`;
    return code;
}

javascriptGenerator.forBlock['BOSS_BAR_SET_TITLE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_boo = generator.valueToCode(block, 'boo', Order.NONE);

    const code = `#setTitle(${value_name},${value_boo});`;
    return code;
}

javascriptGenerator.forBlock['BOSS_BAR_SET_REMOVE_ALL'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#removeAll(${value_name});`;
    return code;
}