import {scoreboard_contents} from "@/data/customize/category/contents/scoreboard_contents";

export const scoreboard_category = [
    {
        "kind": "category",
        "name": "计分板",
        "colour": "#805BA5",
        "contents": [
            ...scoreboard_contents
        ]
    }
]