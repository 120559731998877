import {javascriptGenerator, Order} from "blockly/javascript";

javascriptGenerator.forBlock['EVENT_SET_CANCELLED'] = function (block, generator) {
    const value_event = generator.valueToCode(block, 'event', Order.NONE);
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setCancelled(${value_event},${value_name});`;
    return code;
}

javascriptGenerator.forBlock['EVENT_NOW'] = function () {

    const code = 'αEVENT@EVENT';
    return [code, Order.NONE];
}


// 定义生成器
javascriptGenerator.forBlock['EXAMPLE_BLACK'] = function(block, generator) {
    // 检查是否有子输入（变更器中的子块）
    const childInput = block.getInputTargetBlock('ADD_INPUT');

    let code;
    if (childInput) {
        // 如果有子输入，通过生成子输入的代码
        const childCode = generator.valueToCode(block, 'ADD_INPUT', Order.NONE);
        code = `exampleFunction(${childCode})`; // 假设使用 exampleFunction 来处理输入
    } else {
        // 如果没有子输入，生成默认的代码
        code = `exampleFunction()`;
    }

    // 返回生成的代码
    return [code,Order.NONE];
};
