export const team_contents = [
    {
        "kind": "block",
        "type": "TEAM_CREATE_TEAM",
        "name": "在服务器主要计分板中创建队伍"
    }, {
        "kind": "block",
        "type": "TEAM_CREATE_TEAM_SCOREBOARD",
        "name": "在服务器主要计分板中创建队伍"
    }, {
        "kind": "block",
        "type": "TEAM_GET_TEAM",
        "name": "在服务器主要计分板中获取队伍"
    }, {
        "kind": "block",
        "type": "TEAM_GET_TEAM_SCOREBOARD",
        "name": "在指定的计分板中获取队伍"
    }, {
        "kind": "block",
        "type": "TEAM_GET_PLAYER_TEAM",
        "name": "在服务器主要计分板中获取玩家的队伍"
    }, {
        "kind": "block",
        "type": "TEAM_GET_PLAYER_TEAM_SCOREBOARD",
        "name": "在指定的计分板中获取玩家的队伍"
    }, {
        "kind": "block",
        "type": "TEAM_ADD_PLAYER_2_TEAM",
        "name": "将玩家添加到主要计分板中某个队伍中"
    }, {
        "kind": "block",
        "type": "TEAM_ADD_PLAYER_2_TEAM_TEAM",
        "name": "将玩家添加到某个队伍中"
    }, {
        "kind": "block",
        "type": "TEAM_IS_PLAYER_IN_TEAM",
        "name": "判断玩家是否在主要计分板中某个队伍中"
    }, {
        "kind": "block",
        "type": "TEAM_IS_PLAYER_IN_TEAM_TEAM",
        "name": "判断玩家是否在主要计分板中某个队伍中"
    }, {
        "kind": "block",
        "type": "TEAM_REMOVE_PLAYER_4_TEAM",
        "name": "将玩家从主要计分板中某个队伍中移除"
    }, {
        "kind": "block",
        "type": "TEAM_REMOVE_PLAYER_4_TEAM_TEAM",
        "name": "将玩家从某个队伍中移除"
    }, {
        "kind": "block",
        "type": "TEAM_DELETE_TEAM",
        "name": "将服务器主要计分板中某个队伍删除"
    }, {
        "kind": "block",
        "type": "TEAM_DELETE_TEAM_TEAM",
        "name": "将某个队伍删除"
    }, {
        "kind": "block",
        "type": "TEAM_GET_COLOR",
        "name": "获取队伍的颜色"
    }, {
        "kind": "block",
        "type": "TEAM_GET_ENTRIES",
        "name": "获取队伍中所有的内容"
    }, {
        "kind": "block",
        "type": "TEAM_GET_DISPLAY_NAME",
        "name": "获取队伍的显示名"
    }, {
        "kind": "block",
        "type": "TEAM_GET_NAME_TAG_VISIBILITY",
        "name": "获取队伍头顶Tag可视性的类型"
    }, {
        "kind": "block",
        "type": "TEAM_GET_NAME",
        "name": "获取队伍的名字"
    }, {
        "kind": "block",
        "type": "TEAM_GET_PLAYER",
        "name": "获取队伍中所有的玩家"
    }, {
        "kind": "block",
        "type": "TEAM_GET_PREFIX",
        "name": "获取队伍的前缀"
    }, {
        "kind": "block",
        "type": "TEAM_GET_SUFFIX",
        "name": "获取队伍的后缀"
    }, {
        "kind": "block",
        "type": "TEAM_GET_SCOREBOARD",
        "name": "获取队伍的计分板"
    }, {
        "kind": "block",
        "type": "TEAM_GET_SIZE",
        "name": "获取队伍的大小"
    }, {
        "kind": "block",
        "type": "TEAM_SET_ALLOW_FRIENDLY_FIRE",
        "name": "设置队伍中的成员能否互相攻击"
    }, {
        "kind": "block",
        "type": "TEAM_SET_CAN_SEE_FRIENDLY_INVISIBLE",
        "name": "设置队伍中成员能否看见处于隐身状态效果下的队友"
    }, {
        "kind": "block",
        "type": "TEAM_SET_COLOR",
        "name": "设置队伍的颜色"
    }, {
        "kind": "block",
        "type": "TEAM_SET_DISPLAY_NAME",
        "name": "设置队伍的显示名"
    }, {
        "kind": "block",
        "type": "TEAM_SET_NAME_TAG_VISIBILITY",
        "name": "设置队伍头顶Tag可视性的类型"
    }, {
        "kind": "block",
        "type": "TEAM_SET_PREFIX",
        "name": "设置队伍的前缀"
    }, {
        "kind": "block",
        "type": "TEAM_SET_SUFFIX",
        "name": "设置队伍的后缀"
    },
]