export const boss_bar_contents = [
    {
        "kind": "block",
        "type": "BOSS_BAR_CREATE_BOSS_BAR",
        "name": "创建一条BossBar"
    }, {
        "kind": "block",
        "type": "BOSS_BAR_ADD_BOSS_BAR",
        "name": "将某个BossBar展示给某玩家"
    }, {
        "kind": "block",
        "type": "BOSS_BAR_REMOVE_BOSS_BAR",
        "name": "将玩家的某个BossBar移除"
    }, {
        "kind": "block",
        "type": "BOSS_BAR_SET_PROGRESS",
        "name": "设置BossBar的进度条"
    }, {
        "kind": "block",
        "type": "BOSS_BAR_SET_VISIBLE",
        "name": "设置BossBar的可见性"
    }, {
        "kind": "block",
        "type": "BOSS_BAR_SET_COLOR",
        "name": "设置BossBar颜色"
    }, {
        "kind": "block",
        "type": "BOSS_BAR_SET_STYLE",
        "name": "设置BossBar类型"
    }, {
        "kind": "block",
        "type": "BOSS_BAR_SET_TITLE",
        "name": "设置BossBar标题"
    }, {
        "kind": "block",
        "type": "BOSS_BAR_SET_REMOVE_ALL",
        "name": "移除BossBar所有玩家"
    },

]