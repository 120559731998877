import i18n from "@/i18n";

export const function_block = [
    {
        "type": "Function_block",
        "tooltip": i18n.t("translations.function_block.tooltip"),
        "helpUrl": "",
        "message0": i18n.t("translations.function_block.message0"),
        "args0": [
            {
                "type": "field_input",
                "name": "FUNCTION_NAME",
                "text": "测试方法"
            },
            {
                "type": "input_end_row",
                "name": "1"
            },
            {
                "type": "field_input",
                "name": "FUNCTION_DESCRIPTION",
                "text": "测试方法"
            },
            {
                "type": "input_end_row",
                "name": "2"
            },
            {
                "type": "field_checkbox",
                "name": "ENABLE",
                "checked": "TRUE"
            },
            {
                "type": "input_end_row",
                "name": "3"
            },
            {
                "type": "input_statement",
                "name": "FUNCTION",
                "check": "flow"
            }
        ],
        "colour": 285,
        "inputsInline": false
    },
    {
        "type": "TRIGGER_BLOCK",
        "tooltip": i18n.t("translations.trigger_block.tooltip"),
        "helpUrl": "",
        "message0": i18n.t("translations.trigger_block.message0"),
        "args0": [
            {
                "type": "input_end_row",
                "name": "1"
            },
            {
                "type": "input_end_row",
                "name": "2"
            },
            {
                "type": "input_end_row",
                "name": "3"
            },
            {
                "type": "input_statement",
                "name": "4"
            }
        ],
        "colour": 60,
        "inputsInline": false,
    },
    {
        "type": "TRIGGER_BLOCK_PAPI",
        "tooltip": i18n.t("translations.trigger_block_papi.tooltip"),
        "helpUrl": "",
        "message0": i18n.t("translations.trigger_block_papi.message0"),
        "args0": [
            {
                "type": "input_end_row",
                "name": "1"
            },
            {
                "type": "input_end_row",
                "name": "2"
            },
            {
                "type": "input_end_row",
                "name": "3"
            },
            {
                "type": "input_end_row",
                "name": "4"
            },
            {
                "type": "input_statement",
                "name": "5"
            },
            {
                "type": "input_end_row",
                "name": "6"
            },
            {
                "type": "input_value",
                "name": "7",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "colour": 60,
        "inputsInline": false,
    }



]
