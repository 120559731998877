import i18n from "@/i18n";

export const message_block = [
    {
        "type": "MESSAGE_PLAYER_SEND_MESSAGE",
        "tooltip": i18n.t("message.player_send_message_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("message.player_send_message_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "1",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "2",
                "check": "string"
            },
            {
                "type": "input_dummy",
                "name": "3"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 105,
        "inputsInline": true
    },
    {
        "type": "MESSAGE_PLAYER_SEND_MESSAGE_ALL",
        "tooltip": i18n.t("message.player_send_message_all_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("message.player_send_message_all_message0"),
        "args0": [
            {
                "type": "input_dummy",
                "name": "1"
            },
            {
                "type": "input_value",
                "name": "2",
                "check": "string"
            },
            {
                "type": "input_dummy",
                "name": "3"
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "colour": 105,
        "inputsInline": true
    },
    {
        "type": "MESSAGE_SET_JOIN_MESSAGE",
        "tooltip": i18n.t("message.set_join_message_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("message.set_join_message_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 90
    },
    {
        "type": "MESSAGE_SET_QUIT_MESSAGE",
        "tooltip": i18n.t("message.set_quit_message_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("message.set_quit_message_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 90
    },
    {
        "type": "MESSAGE_SET_CHAT_FORMAT",
        "tooltip": i18n.t("message.set_chat_format_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("message.set_chat_format_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME ",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 90
    },
    {
        "type": "MESSAGE_GET_CHAT_MESSAGE",
        "tooltip": i18n.t("message.get_chat_message_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("message.get_chat_message_message0"),
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 135
    },
    {
        "type": "MESSAGE_GET_COMMAND",
        "tooltip": i18n.t("message.get_command_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("message.get_command_message0"),
        "args0": [
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 135
    },
    {
        "type": "MESSAGE_GET_PAPI_RESULT",
        "tooltip": i18n.t("message.get_papi_result_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("message.get_papi_result_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "string",
        "colour": 135
    },
    {
        "type": "MESSAGE_GET_PAPI_RESULT_PLAYER",
        "tooltip": i18n.t("message.get_papi_result_player_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("message.get_papi_result_player_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "PLAYER",
                "align": "RIGHT",
                "check": "PLAYER"
            }
        ],
        "output": "string",
        "colour": 135
    }



]