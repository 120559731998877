import {javascriptGenerator, Order} from "blockly/javascript";

// 将某个数据存放在全局变量缓存中
javascriptGenerator.forBlock['DATA_SOURCE_PUT_CACHE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_value = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#putCache(${value_name},${value_value});`;
    return code;
}

// 从全局变量缓存中拿一个数据
javascriptGenerator.forBlock['DATA_SOURCE_GET_CACHE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getCache(${value_name})`;
    return [code, Order.NONE];
}

// 从全局变量缓存中删除一个数据
javascriptGenerator.forBlock['DATA_SOURCE_REMOVE_CACHE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#removeCache(${value_name});`;
    return code;
}

javascriptGenerator.forBlock['DATA_SOURCE_CONTAINS_KEY_CACHE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#containsKeyCache(${value_name})`;
    return [code, Order.NONE];
}

// 将某个数据存放在数据库中
javascriptGenerator.forBlock['DATA_SOURCE_PUT_DATA_BASE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_value = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#putDatabase(${value_name},${value_value});`;
    return code;
}

// 从数据库中拿一个数据
javascriptGenerator.forBlock['DATA_SOURCE_GET_DATA_BASE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getDatabase(${value_name})`;
    return [code, Order.NONE];
}

// 从数据库中删除一个数据
javascriptGenerator.forBlock['DATA_SOURCE_REMOVE_DATA_BASE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#removeDatabase(${value_name});`;
    return code;
}


javascriptGenerator.forBlock['DATA_SOURCE_CONTAINS_KEY_DATABASE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#containsKeyDatabase(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['DATA_SOURCE_GET_STRING_FROM_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getStringFromConfig(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['DATA_SOURCE_GET_LONG_FROM_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getLongFromConfig(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['DATA_SOURCE_GET_DOUBLE_FROM_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getDoubleFromConfig(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['DATA_SOURCE_GET_BOOLEAN_FROM_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getBooleanFromConfig(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['DATA_SOURCE_PUT_STRING_2_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_value = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#putString2Config(${value_name},${value_value});`;
    return code;
}

javascriptGenerator.forBlock['DATA_SOURCE_PUT_LONG_2_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_value = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#putLong2Config(${value_name},${value_value});`;
    return code;
}

javascriptGenerator.forBlock['DATA_SOURCE_PUT_DOUBLE_2_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_value = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#putDouble2Config(${value_name},${value_value});`;
    return code;
}

javascriptGenerator.forBlock['DATA_SOURCE_PUT_BOOLEAN_2_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_value = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#putBoolean2Config(${value_name},${value_value});`;
    return code;
}

javascriptGenerator.forBlock['DATA_SOURCE_GET_STRING_LIST_FROM_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getStringListFromConfig(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['DATA_SOURCE_GET_LONG_LIST_FROM_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getLongListFromConfig(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['DATA_SOURCE_GET_DOUBLE_LIST_FROM_CONFIG'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getDoubleListFromConfig(${value_name})`;
    return [code, Order.NONE];
}