import {javascriptGenerator, Order} from "blockly/javascript";

/**
 * 创建玩家变量
 */
javascriptGenerator.forBlock['FIELD_CREATE_PLAYER'] = function (block, generator) {
    const text_player_field_name = block.getFieldValue('PLAYER_FIELD_NAME');

    const statement_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return `αPLAYER@${text_player_field_name} = ${statement_name};`
}
/**
 * 获取玩家变量
 */
javascriptGenerator.forBlock['FIELD_GET_PLAYER'] = function (block) {
    const text_player_field_name = block.getFieldValue('PLAYER_FIELD_NAME');

    return [`αPLAYER@${text_player_field_name}`, Order.NONE]
}

// 创建字符串变量
javascriptGenerator.forBlock['FIELD_CREATE_STRING'] = function (block, generator) {
    const text_string_name = block.getFieldValue('STRING_NAME');
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `αSTRING@${text_string_name} = ${value_name};`;
    return code;
}

// 获取字符串变量
javascriptGenerator.forBlock['FIELD_GET_STRING'] = function (block) {
    const text_name = block.getFieldValue('NAME');
    const code = `αSTRING@${text_name}`;
    return [code, Order.NONE];
}

// 创建数字变量
javascriptGenerator.forBlock['FIELD_CREATE_NUMBER'] = function (block, generator) {
    const text_number = block.getFieldValue('NUMBER');
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `αDOUBLE@${text_number} = ${value_name};`;
    return code;
}

// 获取数字变量
javascriptGenerator.forBlock['FIELD_GET_NUMBER'] = function (block) {
    const text_name = block.getFieldValue('NAME');

    const code = `αDOUBLE@${text_name}`;
    return [code, Order.NONE];
}

// 创建布尔变量
javascriptGenerator.forBlock['FIELD_CREATE_BOOLEAN'] = function (block, generator) {
    const text_boolean = block.getFieldValue('boolean');
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `αBOOLEAN@${text_boolean} = ${value_name};`;
    return code;
}

// 获取布尔变量
javascriptGenerator.forBlock['FIELD_GET_BOOLEAN'] = function (block) {
    const text_name = block.getFieldValue('NAME');

    const code = `αBOOLEAN@${text_name}`;
    return [code, Order.NONE];
}

// 创建位置变量
javascriptGenerator.forBlock['FIELD_CREATE_LOCATION'] = function (block, generator) {
    const text_location = block.getFieldValue('location');
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `αLOCATION@${text_location} = ${value_name};`;
    return code;
}

// 获取位置变量
javascriptGenerator.forBlock['FIELD_GET_LOCATION'] = function (block) {
    const text_location = block.getFieldValue('location');

    const code = `αLOCATION@${text_location}`;
    return [code, Order.NONE];
}

// 创建世界变量
javascriptGenerator.forBlock['FIELD_CREATE_WORLD'] = function (block, generator) {
    const text_world = block.getFieldValue('world');
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `αWORLD@${text_world} = ${value_name};`;
    return code;
}

// 获取世界变量
javascriptGenerator.forBlock['FIELD_GET_WORLD'] = function (block) {
    const text_name = block.getFieldValue('NAME');

    const code = `αWORLD@${text_name}`;
    return [code, Order.NONE];
}


// 创建BOSSBAR变量
javascriptGenerator.forBlock['FIELD_CREATE_BOSS_BAR'] = function (block, generator) {
    const text_world = block.getFieldValue('bossbar');
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `αBOSSBAR@${text_world} = ${value_name};`;
    return code;
}

// 获取BOSSBAR变量
javascriptGenerator.forBlock['FIELD_GET_BOSS_BAR'] = function (block) {
    const text_name = block.getFieldValue('NAME');

    const code = `αBOSSBAR@${text_name}`;
    return [code, Order.NONE];
}


// 创建计分板变量
javascriptGenerator.forBlock['FIELD_CREATE_SCOREBOARD'] = function (block, generator) {
    const text_world = block.getFieldValue('scoreboard');
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `αSCOREBOARD@${text_world} = ${value_name};`;
    return code;
}

// 获取计分板变量
javascriptGenerator.forBlock['FIELD_GET_SCOREBOARD'] = function (block) {
    const text_name = block.getFieldValue('NAME');

    const code = `αSCOREBOARD@${text_name}`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['FIELD_CREATE_LIST'] = function (block, generator) {
    const text_world = block.getFieldValue('NAME2');
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `αLIST@${text_world} = ${value_name};`;
    return code;
}


javascriptGenerator.forBlock['FIELD_GET_LIST'] = function (block) {
    const text_name = block.getFieldValue('NAME2');

    const code = `αLIST@${text_name}`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['FIELD_CREATE_MAP'] = function (block, generator) {
    const text_world = block.getFieldValue('NAME');
    const value_name = generator.valueToCode(block, 'NAME2', Order.NONE);

    const code = `αMAP@${text_world} = ${value_name};`;
    return code;
}


javascriptGenerator.forBlock['FIELD_GET_MAP'] = function (block) {
    const text_name = block.getFieldValue('NAME');

    const code = `αMAP@${text_name}`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['FIELD_CREATE_TEAM'] = function (block, generator) {
    const text_world = block.getFieldValue('NAME');
    const value_name = generator.valueToCode(block, 'NAME2', Order.NONE);

    const code = `αTEAM@${text_world} = ${value_name};`;
    return code;
}


javascriptGenerator.forBlock['FIELD_GET_TEAM'] = function (block) {
    const text_name = block.getFieldValue('NAME');

    const code = `αTEAM@${text_name}`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['FIELD_CREATE_BLOCK'] = function (block, generator) {
    const text_world = block.getFieldValue('NAME');
    const value_name = generator.valueToCode(block, 'NAME2', Order.NONE);

    const code = `αBLOCK@${text_world} = ${value_name};`;
    return code;
}


javascriptGenerator.forBlock['FIELD_GET_BLOCK'] = function (block) {
    const text_name = block.getFieldValue('NAME');

    const code = `αBLOCK@${text_name}`;
    return [code, Order.NONE];
}