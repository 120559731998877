import {block_contents} from "@/data/customize/category/contents/block_contents";

export const block_category = [
    {
        "kind": "category",
        "name": "方块组件",
        "colour": "#8A2BE2",
        "contents": [
            ...block_contents
        ]
    }
]