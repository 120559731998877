import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2';
import i18n from "@/i18n";
import getBrowserLanguage from "@/utils/getBrowserLanguage";

Vue.use(ElementUI);
Vue.use(VueClipboard);

//注册全局组件
console.log()


if (!localStorage.getItem("lang")){
  if (getBrowserLanguage()==='en_US'){
    localStorage.setItem("lang","en_US")
  }else{
    localStorage.setItem("lang", "zh_CN")
  }
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
