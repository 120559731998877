import {javascriptGenerator, Order} from "blockly/javascript";

javascriptGenerator.forBlock['BLOCK_GET_BLOCK'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getBlock(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BLOCK_SET_TYPE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    const code = `#setType(${value_name},${value_name2});`;
    return code;
}

javascriptGenerator.forBlock['BLOCK_GET_TYPE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getType(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BLOCK_GET_BLOCK_LOCATION'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getBlock(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BLOCK_BREAK_NATURALLY'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#breakNaturally(${value_name});`;
    return code;
}