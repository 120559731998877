export const scoreboard_contents = [
    {
        "kind": "block",
        "type": "SCOREBOARD_CREATE",
        "name": "创建一个计分板"
    }, {
        "kind": "block",
        "type": "SCOREBOARD_CREATE_LIST",
        "name": "创建一个计分板"
    }, {
        "kind": "block",
        "type": "SCOREBOARD_SET_SCOREBOARD",
        "name": "给玩家设定一个计分板"
    }, {
        "kind": "block",
        "type": "SCOREBOARD_RESET_SCOREBOARD",
        "name": "重置一个玩家的计分板"
    },

]