import i18n from "@/i18n";

export const boss_bar_block = [
    {
        "type": "BOSS_BAR_CREATE_BOSS_BAR",
        "tooltip": i18n.t("bossBar.boss_bar_create_boss_bar_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("bossBar.boss_bar_create_boss_bar_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "COLOR",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "TAG",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "number",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": "bossbar",
        "colour": 345
    },
    {
        "type": "BOSS_BAR_ADD_BOSS_BAR",
        "tooltip": i18n.t("bossBar.boss_bar_add_boss_bar_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("bossBar.boss_bar_add_boss_bar_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "bossbar",
                "align": "RIGHT",
                "check": "bossbar"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    },
    {
        "type": "BOSS_BAR_REMOVE_BOSS_BAR",
        "tooltip": i18n.t("bossBar.boss_bar_remove_boss_bar_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("bossBar.boss_bar_remove_boss_bar_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "bossbar",
                "align": "RIGHT",
                "check": "bossbar"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    },
    {
        "type": "BOSS_BAR_SET_PROGRESS",
        "tooltip": "",
        "helpUrl": "",
        "message0": i18n.t("bossBar.boss_bar_set_progress_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345,
        "inputsInline": false
    },
    {
        "type": "BOSS_BAR_SET_VISIBLE",
        "tooltip": i18n.t("bossBar.boss_bar_set_visible_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("bossBar.boss_bar_set_visible_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "boo",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    },
    {
        "type": "BOSS_BAR_SET_COLOR",
        "tooltip": i18n.t("bossBar.boss_bar_set_color_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("bossBar.boss_bar_set_color_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "boo",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    },
    {
        "type": "BOSS_BAR_SET_STYLE",
        "tooltip": i18n.t("bossBar.boss_bar_set_style_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("bossBar.boss_bar_set_style_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "boo",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    },
    {
        "type": "BOSS_BAR_SET_TITLE",
        "tooltip": i18n.t("bossBar.boss_bar_set_title_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("bossBar.boss_bar_set_title_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            },
            {
                "type": "input_value",
                "name": "boo",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    },
    {
        "type": "BOSS_BAR_SET_REMOVE_ALL",
        "tooltip": i18n.t("bossBar.boss_bar_set_remove_all_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("bossBar.boss_bar_set_remove_all_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "bossbar"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 345
    }


]