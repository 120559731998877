import i18n from "@/i18n";

export const player_block = [
    {
        "type": "PLAYER_GET_EVENT",
        "tooltip": i18n.t("player.get_event_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_event_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "event"
            }
        ],
        "output": "PLAYER",
        "colour": 240
    },
    {
        "type": "PLAYER_GET_PLAYER",
        "tooltip": i18n.t("player.get_player_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_player_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "PLAYER",
        "colour": 240
    }, {
        "type": "PLAYER_GET_ENCHANTER",
        "tooltip": i18n.t("player.get_enchanter_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_enchanter_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "event"
            }
        ],
        "output": "PLAYER",
        "colour": 240
    },
    {
        "type": "PLAYER_GET_HEALTH",
        "tooltip": i18n.t("player.get_health_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_health_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": [
            "double",
            "number"
        ],
        "colour": 240,
    }
    ,
    {
        "type": "PLAYER_SET_HEALTH",
        "tooltip": i18n.t("player.set_health_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_health_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NUMBER",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
    },
    {
        "type": "PLAYER_GET_NAME",
        "tooltip": i18n.t("player.get_name_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_name_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "string",
        "colour": 240
    },
    {
        "type": "PLAYER_HAS_PERMISSION",
        "tooltip": i18n.t("player.has_permission_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.has_permission_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "PERMISSION",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "output": "boolean_block",
        "colour": 210
    },
    {
        "type": "PLAYER_SET_FLY",
        "tooltip": i18n.t("player.set_fly_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_fly_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "BOOLEAN",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SEND_TITLE",
        "tooltip": i18n.t("player.send_title_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.send_title_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "1",
                "check": "PLAYER"
            },
            {
                "type": "input_end_row",
                "name": "4"
            },
            {
                "type": "input_value",
                "name": "2",
                "check": "string"
            },
            {
                "type": "input_end_row",
                "name": "8"
            },
            {
                "type": "input_value",
                "name": "3",
                "check": "string"
            },
            {
                "type": "input_end_row",
                "name": "9"
            },
            {
                "type": "input_end_row",
                "name": "5"
            },
            {
                "type": "field_number",
                "name": "fadeIn",
                "value": 10,
                "min": 0
            },
            {
                "type": "input_dummy",
                "name": "fadeIn_block"
            },
            {
                "type": "input_end_row",
                "name": "6"
            },
            {
                "type": "field_number",
                "name": "stay",
                "value": 70,
                "min": 0
            },
            {
                "type": "input_dummy",
                "name": "stay_block"
            },
            {
                "type": "input_end_row",
                "name": "7"
            },
            {
                "type": "field_number",
                "name": "fadeOut",
                "value": 20,
                "min": 0
            },
            {
                "type": "input_dummy",
                "name": "fadeOut_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": true
    },
    {
        "type": "PLAYER_RESET_TITLE",
        "tooltip": i18n.t("player.reset_title_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.reset_title_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_LOCATION",
        "tooltip": i18n.t("player.get_location_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_location_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "location",
        "colour": 240
    },
    {
        "type": "PLAYER_GET_UNIQUE_ID",
        "tooltip": i18n.t("player.get_unique_id_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_unique_id_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "string",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_RESPAWN_LOCATION",
        "tooltip": i18n.t("player.set_respawn_location_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_respawn_location_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "location"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_MAX_HEALTH",
        "tooltip": i18n.t("player.get_max_health_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_max_health_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240
    },
    {
        "type": "PLAYER_GET_LEVEL",
        "tooltip": i18n.t("player.get_level_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_level_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_LEVEL",
        "tooltip": i18n.t("player.set_level_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_level_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "LEVEL",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_FLY_SPEED",
        "tooltip": i18n.t("player.get_fly_speed_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_fly_speed_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_FLY_SPEED",
        "tooltip": i18n.t("player.set_fly_speed_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_fly_speed_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_PING",
        "tooltip": i18n.t("player.get_ping_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_ping_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240,
    }
    ,
    {
        "type": "PLAYER_GET_FOOD_LEVEL",
        "tooltip": i18n.t("player.get_food_level_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_food_level_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_FOOD_LEVEL",
        "tooltip": i18n.t("player.set_food_level_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_food_level_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_ALLOW_FLIGHT",
        "tooltip": i18n.t("player.get_allow_flight_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_allow_flight_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "boolean_block",
        "colour": 240
    },
    {
        "type": "PLAYER_GET_BED_LOCATION",
        "tooltip": i18n.t("player.get_bed_location_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_bed_location_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_end_row",
                "name": "3"
            },
            {
                "type": "input_end_row",
                "name": "2"
            }
        ],
        "output": [
            "location",
            "null"
        ],
        "colour": 240
    },
    {
        "type": "PLAYER_GET_ATTACK_COOLDOWN",
        "tooltip": i18n.t("player.get_attack_cooldown_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_attack_cooldown_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240
    },
    {
        "type": "PLAYER_CHECK_IS_OP",
        "tooltip": i18n.t("player.check_is_op_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.check_is_op_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "boolean_block",
        "colour": 210
    },
    {
        "type": "PLAYER_TELEPORT",
        "tooltip": i18n.t("player.teleport_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.teleport_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "player",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "location",
                "align": "RIGHT",
                "check": "location"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_MAX_HEALTH",
        "tooltip": i18n.t("player.set_max_health_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_max_health_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_LIST_NAME",
        "tooltip": i18n.t("player.get_list_name_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_list_name_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            }
        ],
        "output": "string",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_LIST_NAME",
        "tooltip": i18n.t("player.set_list_name_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_list_name_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_WEATHER",
        "tooltip": i18n.t("player.set_weather_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_weather_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "align": "RIGHT",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_RESET_WEATHER",
        "tooltip": i18n.t("player.reset_weather_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.reset_weather_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_TIME",
        "tooltip": i18n.t("player.set_time_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_time_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "field_number",
                "name": "TIME",
                "value": 0
            },
            {
                "type": "input_dummy",
                "name": "TIME",
                "align": "RIGHT"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_SET_TIME2",
        "tooltip": i18n.t("player.set_time2_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_time2_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "field_number",
                "name": "TIME",
                "value": 0
            },
            {
                "type": "input_dummy",
                "name": "TIME",
                "align": "RIGHT"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_RESET_TIME",
        "tooltip": i18n.t("player.reset_time_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.reset_time_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_GET_WALK_SPEED",
        "tooltip": i18n.t("player.get_walk_speed_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_walk_speed_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "number",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_WALK_SPEED",
        "tooltip": i18n.t("player.set_walk_speed_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_walk_speed_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "field_number",
                "name": "NAME",
                "value": 1,
                "min": -1,
                "max": 1
            },
            {
                "type": "input_dummy",
                "name": "SPEED",
                "align": "RIGHT"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_SET_CAN_PICKUP_ITEMS",
        "tooltip": i18n.t("player.set_can_pickup_items_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_can_pickup_items_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_CLOSE_INVENTORY",
        "tooltip": i18n.t("player.close_inventory_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.close_inventory_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SPAWN_PARTICLE",
        "tooltip": i18n.t("player.spawn_particle_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.spawn_particle_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "string",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "X",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Y",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "Z",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225,
        "inputsInline": false
    },
    {
        "type": "PLAYER_SPAWN_PARTICLE_LOCATION",
        "tooltip": i18n.t("player.spawn_particle_location_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.spawn_particle_location_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "location",
                "align": "RIGHT",
                "check": "location"
            },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_LIST_HEADER",
        "tooltip": i18n.t("player.set_list_header_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_list_header_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_LIST_FOOTER",
        "tooltip": i18n.t("player.set_list_footer_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_list_footer_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_SET_LIST_HEADER_FOOTER",
        "tooltip": i18n.t("player.set_list_header_footer_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_list_header_footer_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "UP",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "DOWN",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_KICK",
        "tooltip": i18n.t("player.kick_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.kick_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SUDO_CHAT",
        "tooltip": i18n.t("player.sudo_chat_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.sudo_chat_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SET_ABSORPTION_AMOUNT",
        "tooltip": i18n.t("player.set_absorption_amount_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_absorption_amount_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SET_INVISIBLE",
        "tooltip": i18n.t("player.set_invisible_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_invisible_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    },
    {
        "type": "PLAYER_IS_INVISIBLE",
        "tooltip": i18n.t("player.is_invisible_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.is_invisible_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        }],
        "output": "boolean_block",
        "colour": 210
    }, {
        "type": "PLAYER_IS_IN_WATER",
        "tooltip": i18n.t("player.is_in_water_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.is_in_water_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        }],
        "output": "boolean_block",
        "colour": 210
    }, {
        "type": "PLAYER_SEND_SOUND",
        "tooltip": i18n.t("player.send_sound_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.send_sound_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "PLAYER",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "SOUND",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "size",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_GET_GAME_MODE",
        "tooltip": i18n.t("player.get_game_mode_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_game_mode_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        }],
        "output": "string",
        "colour": 240
    },
    {
        "type": "PLAYER_SET_GAME_MODE",
        "tooltip": i18n.t("player.set_game_mode_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_game_mode_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_POTION_EFFECT",
        "tooltip": i18n.t("player.potion_effect_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.potion_effect_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "POTION",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "time",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "level",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "colour": 225
    }, {
        "type": "PLAYER_SET_GLOWING",
        "tooltip": i18n.t("player.set_glowing_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_glowing_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "boolean",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "colour": 225
    }, {
        "type": "PLAYER_GIVE_EXP",
        "tooltip": i18n.t("player.give_exp_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.give_exp_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "EXP",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_GIVE_EXP_LEVELS",
        "tooltip": i18n.t("player.give_exp_levels_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.give_exp_levels_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "NUMBER",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SET_COMPASS_TARGET",
        "tooltip": i18n.t("player.set_compass_target_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_compass_target_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "location"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_HIDE_PLAYER",
        "tooltip": i18n.t("player.hide_player_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.hide_player_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER_A",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "PLAYER_B",
                "align": "RIGHT",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SHOW_PLAYER",
        "tooltip": i18n.t("player.show_player_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.show_player_message0"),
        "args0": [{
            "type": "input_value",
            "name": "PLAYER_A",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "PLAYER_B",
                "align": "RIGHT",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SET_SLEEP_IGNORED",
        "tooltip": i18n.t("player.set_sleep_ignored_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_sleep_ignored_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "boo",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_IS_SLEEPING_IGNORED",
        "tooltip": i18n.t("player.is_sleeping_ignored_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.is_sleeping_ignored_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        }],
        "output": "boolean_block",
        "colour": 240
    }, {
        "type": "PLAYER_IS_SLEEPING",
        "tooltip": i18n.t("player.is_sleeping_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.is_sleeping_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        }],
        "output": "boolean_block",
        "colour": 240
    }, {
        "type": "PLAYER_SET_TOTAL_EXPERIENCE",
        "tooltip": i18n.t("player.set_total_experience_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_total_experience_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "number",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_GET_TOTAL_EXPERIENCE",
        "tooltip": i18n.t("player.get_total_experience_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_total_experience_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        }],
        "output": "number",
        "colour": 240
    }, {
        "type": "PLAYER_ADD_ITEM",
        "tooltip": i18n.t("player.add_item_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.add_item_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "item",
                "align": "RIGHT",
                "check": "string"
            },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SET_PLAYER_GLOW_4_RECEIVER",
        "tooltip": i18n.t("player.set_player_glow_4_receiver_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_player_glow_4_receiver_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "B",
                "align": "RIGHT",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_REMOVE_PLAYER_GLOW_4_RECEIVER",
        "tooltip": i18n.t("player.remove_player_glow_4_receiver_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.remove_player_glow_4_receiver_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            },
            {
                "type": "input_value",
                "name": "B",
                "align": "RIGHT",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_SET_VELOCITY",
        "tooltip": i18n.t("player.set_velocity_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_velocity_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "x",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "y",
                "align": "RIGHT",
                "check": "number"
            },
            {
                "type": "input_value",
                "name": "z",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_STP",
        "tooltip": i18n.t("player.stp_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.stp_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "server",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_GIVE_BALANCE",
        "tooltip": i18n.t("player.give_balance_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.give_balance_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_TAKE_BALANCE",
        "tooltip": i18n.t("player.take_balance_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.take_balance_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_GET_BALANCE",
        "tooltip": i18n.t("player.get_balance_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_balance_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        }],
        "output": "number",
        "colour": 240
    }, {
        "type": "PLAYER_HAS_BALANCE",
        "tooltip": i18n.t("player.has_balance_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.has_balance_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "num",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "output": "boolean_block",
        "colour": 240
    }, {
        "type": "PLAYER_GET_KILLER",
        "tooltip": i18n.t("player.get_killer_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_killer_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        }],
        "output": "PLAYER",
        "colour": 240
    }, {
        "type": "PLAYER_SET_ACTIONBAR",
        "tooltip": i18n.t("player.set_actionbar_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_actionbar_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "text",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_GET_ITEM_IN_HAND",
        "tooltip": i18n.t("player.get_item_in_hand_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_item_in_hand_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        }],
        "output": "string",
        "colour": 240
    }, {
        "type": "PLAYER_GET_DISPLAY_NAME",
        "tooltip": i18n.t("player.get_display_name_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_display_name_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        }],
        "output": "string",
        "colour": 240
    }, {
        "type": "PLAYER_SET_DISPLAY_NAME",
        "tooltip": i18n.t("player.set_display_name_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.set_display_name_message0"),
        "args0": [{
            "type": "input_value",
            "name": "NAME",
            "check": "PLAYER"
        },
            {
                "type": "input_value",
                "name": "text",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 225
    }, {
        "type": "PLAYER_GET_PAPI_PLAYER",
        "tooltip": i18n.t("player.get_papi_player_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("player.get_papi_player_message0"),
        "args0": [],
        "output": "PLAYER",
        "colour": 240
    }


]