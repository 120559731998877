import i18n from "@/i18n";

export const field_block = [
    {
        "type": "FIELD_CREATE_PLAYER",
        "tooltip": i18n.t("field.create_player_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_player_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "PLAYER_FIELD_NAME",
                "text": "player"
            },
            {
                "type": "input_dummy",
                "name": "PLAYER_FIELD"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "PLAYER"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_PLAYER",
        "tooltip": i18n.t("field.get_player_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_player_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "PLAYER_FIELD_NAME",
                "text": "player"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "PLAYER",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_STRING",
        "tooltip": i18n.t("field.create_string_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_string_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "STRING_NAME",
                "text": "string"
            },
            {
                "type": "input_dummy",
                "name": "STRING_NAME"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_STRING",
        "tooltip": i18n.t("field.get_string_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_string_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "string"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "string",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_NUMBER",
        "tooltip": i18n.t("field.create_number_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_number_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NUMBER",
                "text": "number"
            },
            {
                "type": "input_dummy",
                "name": "NUMBER"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_NUMBER",
        "tooltip": i18n.t("field.get_number_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_number_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "number"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "number",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_BOOLEAN",
        "tooltip": i18n.t("field.create_boolean_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_boolean_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "boolean",
                "text": "boolean"
            },
            {
                "type": "input_dummy",
                "name": "boolean"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "boolean_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_BOOLEAN",
        "tooltip": i18n.t("field.get_boolean_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_boolean_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "boolean"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "boolean_block",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_LOCATION",
        "tooltip": i18n.t("field.create_location_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_location_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "location",
                "text": "location"
            },
            {
                "type": "input_dummy",
                "name": "location"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "location"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_LOCATION",
        "tooltip": i18n.t("field.get_location_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_location_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "location",
                "text": "location"
            },
            {
                "type": "input_dummy",
                "name": "location"
            }
        ],
        "output": "location",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_WORLD",
        "tooltip": i18n.t("field.create_world_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_world_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "world",
                "text": "world"
            },
            {
                "type": "input_dummy",
                "name": "world"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "world"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_WORLD",
        "tooltip": i18n.t("field.get_world_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_world_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "world"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "world",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_BOSS_BAR",
        "tooltip": i18n.t("field.create_boss_bar_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_boss_bar_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "bossbar",
                "text": "bossbar"
            },
            {
                "type": "input_dummy",
                "name": "bossBar"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "bossbar"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_BOSS_BAR",
        "tooltip": i18n.t("field.get_boss_bar_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_boss_bar_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "bossbar"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "bossbar",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_SCOREBOARD",
        "tooltip": i18n.t("field.create_scoreboard_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_scoreboard_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "scoreboard",
                "text": "scoreboard"
            },
            {
                "type": "input_dummy",
                "name": "计分板"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "scoreboard"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_SCOREBOARD",
        "tooltip": i18n.t("field.get_scoreboard_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_scoreboard_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "scoreboard"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "scoreboard",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_LIST",
        "tooltip": i18n.t("field.create_list_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_list_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME2",
                "text": "list"
            },
            {
                "type": "input_dummy",
                "name": "list集合"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "list"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_LIST",
        "tooltip": i18n.t("field.get_list_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_list_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME2",
                "text": "list"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "list",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_MAP",
        "tooltip": i18n.t("field.create_map_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_map_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "map"
            },
            {
                "type": "input_dummy",
                "name": "map"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "map"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_MAP",
        "tooltip": i18n.t("field.get_map_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_map_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "map"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "map",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_TEAM",
        "tooltip": i18n.t("field.create_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_team_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "team"
            },
            {
                "type": "input_dummy",
                "name": "team"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "team"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_TEAM",
        "tooltip": i18n.t("field.get_team_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_team_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "team"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "team",
        "colour": 330
    },
    {
        "type": "FIELD_CREATE_BLOCK",
        "tooltip": i18n.t("field.create_block_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.create_block_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "block"
            },
            {
                "type": "input_dummy",
                "name": "block"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "block_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 330
    },
    {
        "type": "FIELD_GET_BLOCK",
        "tooltip": i18n.t("field.get_block_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("field.get_block_message"),
        "args0": [
            {
                "type": "field_input",
                "name": "NAME",
                "text": "block"
            },
            {
                "type": "input_dummy",
                "name": "NAME"
            }
        ],
        "output": "block_block",
        "colour": 330
    }

]