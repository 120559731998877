import VueI18n from "vue-i18n";
import Vue from "vue";


Vue.use(VueI18n)
let i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zh_CN', // 语言标识
    messages: {
        zh_CN: require('./language/zh_CN'), // 中文
        en_US: require('./language/en_US'), //英文
    }
})
export default i18n;
