export const player_contents = [
    {
        "kind": "block",
        "type": "PLAYER_GET_EVENT",
        "name": "获取玩家实例"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_PLAYER",
        "name": "通过玩家名字获取玩家实例"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_ENCHANTER",
        "name": "通过附魔事件获取玩家实例"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_HEALTH",
        "name": "获取玩家血量"
    },
    {
        "kind": "block",
        "type": "PLAYER_SET_HEALTH",
        "name": "设置玩家血量"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_NAME",
        "name": "获取玩家名字"
    },
    {
        "kind": "block",
        "type": "PLAYER_HAS_PERMISSION",
        "name": "检查是否拥有权限"
    },
    {
        "kind": "block",
        "type": "PLAYER_SET_FLY",
        "name": "设置飞行"
    },
    {
        "kind": "block",
        "type": "PLAYER_SEND_TITLE",
        "name": "给玩家发送一个标题"
    }, {
        "kind": "block",
        "type": "PLAYER_RESET_TITLE",
        "name": "重置标题"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_LOCATION",
        "name": "通过玩家获取坐标"
    },
    {
        "kind": "block",
        "type": "PLAYER_SET_RESPAWN_LOCATION",
        "name": "设置玩家重生点"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_UNIQUE_ID",
        "name": "获取玩家UUID"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_MAX_HEALTH",
        "name": "获取玩家最大血量"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_LEVEL",
        "name": "获取玩家等级"
    },
    {
        "kind": "block",
        "type": "PLAYER_SET_LEVEL",
        "name": "设置玩家等级"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_FLY_SPEED",
        "name": "获取玩家飞行速度"
    },
    {
        "kind": "block",
        "type": "PLAYER_SET_FLY_SPEED",
        "name": "设置玩家飞行速度"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_PING",
        "name": "获取玩家的延迟"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_FOOD_LEVEL",
        "name": "获取玩家的饱食度"
    },
    {
        "kind": "block",
        "type": "PLAYER_SET_FOOD_LEVEL",
        "name": "设置玩家的饱食度"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_ALLOW_FLIGHT",
        "name": "获取玩家是否允许飞行"
    },
    {
        "kind": "block",
        "type": "PLAYER_GET_BED_LOCATION",
        "name": "获取玩家床的位置"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_ATTACK_COOLDOWN",
        "name": "获取玩家攻击冷却 (游戏版本1.9+)"
    }, {
        "kind": "block",
        "type": "PLAYER_CHECK_IS_OP",
        "name": "检查是否是OP"
    }, {
        "kind": "block",
        "type": "PLAYER_TELEPORT",
        "name": "将玩家传送至一个坐标"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_MAX_HEALTH",
        "name": "设置最大血量"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_LIST_NAME",
        "name": "获取Tab列表中名字"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_LIST_NAME",
        "name": "设置Tab列表中名字"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_WEATHER",
        "name": "重置天气"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_TIME",
        "name": "设置时间"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_TIME2",
        "name": "设置时间"
    }, {
        "kind": "block",
        "type": "PLAYER_RESET_TIME",
        "name": "重置时间"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_WALK_SPEED",
        "name": "获取行走速度"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_WALK_SPEED",
        "name": "设置行走速度"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_CAN_PICKUP_ITEMS",
        "name": "设置是否允许捡物品"
    }, {
        "kind": "block",
        "type": "PLAYER_CLOSE_INVENTORY",
        "name": "关闭背包窗口"
    }, {
        "kind": "block",
        "type": "PLAYER_SPAWN_PARTICLE",
        "name": "生成粒子"
    }, {
        "kind": "block",
        "type": "PLAYER_SPAWN_PARTICLE_LOCATION",
        "name": "生成粒子"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_LIST_HEADER",
        "name": "设置TAB列表上方内容"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_LIST_FOOTER",
        "name": "设置TAB列表下方内容"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_LIST_HEADER_FOOTER",
        "name": "设置TAB列表上方和下方内容"
    }, {
        "kind": "block",
        "type": "PLAYER_KICK",
        "name": "踢出玩家"
    }, {
        "kind": "block",
        "type": "PLAYER_SUDO_CHAT",
        "name": "强制某个玩家发送一个消息或命令"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_ABSORPTION_AMOUNT",
        "name": "设置吸收伤害"
    }, {
        "kind": "block",
        "type": "PLAYER_IS_INVISIBLE",
        "name": "设置可见性"
    }, {
        "kind": "block",
        "type": "PLAYER_IS_IN_WATER",
        "name": "检查是否在水中"
    }, {
        "kind": "block",
        "type": "PLAYER_SEND_SOUND",
        "name": "播放声音"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_GAME_MODE",
        "name": "获取玩家游戏模式"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_GAME_MODE",
        "name": "设置玩家游戏模式"
    }, {
        "kind": "block",
        "type": "PLAYER_POTION_EFFECT",
        "name": "设置玩家添加药水效果"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_GLOWING",
        "name": "设置高亮玩家"
    }, {
        "kind": "block",
        "type": "PLAYER_GIVE_EXP",
        "name": "给予玩家经验"
    }, {
        "kind": "block",
        "type": "PLAYER_GIVE_EXP_LEVELS",
        "name": "给予玩家等级"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_COMPASS_TARGET",
        "name": "设置玩家指南针所指向的目标"
    }, {
        "kind": "block",
        "type": "PLAYER_HIDE_PLAYER",
        "name": "设置对A玩家隐藏B玩家"
    }, {
        "kind": "block",
        "type": "PLAYER_SHOW_PLAYER",
        "name": "设置对A玩家显示B玩家"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_SLEEP_IGNORED",
        "name": "设置睡觉时是否忽略该玩家"
    }, {
        "kind": "block",
        "type": "PLAYER_IS_SLEEPING_IGNORED",
        "name": "获取睡觉时是否忽略该玩家"
    }, {
        "kind": "block",
        "type": "PLAYER_IS_SLEEPING",
        "name": "获取玩家是否处于睡觉状态"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_TOTAL_EXPERIENCE",
        "name": "设置玩家当前总经验"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_TOTAL_EXPERIENCE",
        "name": "获得玩家总经验"
    }, {
        "kind": "block",
        "type": "PLAYER_ADD_ITEM",
        "name": "给予玩家物品"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_PLAYER_GLOW_4_RECEIVER",
        "name": "让A玩家发光且仅对B玩家生效"
    }, {
        "kind": "block",
        "type": "PLAYER_REMOVE_PLAYER_GLOW_4_RECEIVER",
        "name": "让A玩家取消发光且仅对B玩家生效"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_VELOCITY",
        "name": "设置玩家矢量"
    }, {
        "kind": "block",
        "type": "PLAYER_STP",
        "name": "将玩家传送到子服务器"
    }, {
        "kind": "block",
        "type": "PLAYER_GIVE_BALANCE",
        "name": "给予玩家金钱"
    }, {
        "kind": "block",
        "type": "PLAYER_TAKE_BALANCE",
        "name": "拿走玩家金钱"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_BALANCE",
        "name": "获取玩家金钱数量"
    }, {
        "kind": "block",
        "type": "PLAYER_HAS_BALANCE",
        "name": "玩家是否拥有相对数量的金钱"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_KILLER",
        "name": "获取击杀这个玩家的人"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_ACTIONBAR",
        "name": "设置玩家的 ActionBar"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_ITEM_IN_HAND",
        "name": "获取玩家当前手中的物品"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_DISPLAY_NAME",
        "name": "获取玩家显示名"
    }, {
        "kind": "block",
        "type": "PLAYER_SET_DISPLAY_NAME",
        "name": "设置玩家显示名"
    }, {
        "kind": "block",
        "type": "PLAYER_GET_PAPI_PLAYER",
        "name": "获取触发该PAPI占位符的玩家"
    },
]
