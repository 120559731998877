export const event_contents = [
    {
        "kind": "block",
        "type": "EVENT_NOW",
        "name": "获取当前事件"
    }, {
        "kind": "block",
        "type": "EVENT_SET_CANCELLED",
        "name": "取消事件"
    }, {
        "kind": "block",
        "type": "EVENT_GET_LOCALE",
        "name": "获取选择的语言，切换语言的时候触发"
    }
]
