import i18n from "@/i18n";

export const block_block = [
    {
        "type": "BLOCK_GET_BLOCK",
        "tooltip": i18n.t("block.block_get_block_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("block.block_get_block_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "event"
            }
        ],
        "output": "block_block",
        "colour": "#8A2BE2"
    },
    {
        "type": "BLOCK_SET_TYPE",
        "tooltip": i18n.t("block.block_set_type_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("block.block_set_type_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "block_block"
            },
            {
                "type": "input_value",
                "name": "NAME2",
                "align": "RIGHT",
                "check": "string"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": "#8A2BE2"
    },
    {
        "type": "BLOCK_GET_TYPE",
        "tooltip": i18n.t("block.block_get_type_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("block.block_get_type_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "block_block"
            }
        ],
        "output": "string",
        "colour": "#8A2BE2"
    },
    {
        "type": "BLOCK_GET_BLOCK_LOCATION",
        "tooltip": i18n.t("block.block_get_block_location_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("block.block_get_block_location_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "output": "block_block",
        "colour": "#8A2BE2"
    },
    {
        "type": "BLOCK_BREAK_NATURALLY",
        "tooltip": i18n.t("block.block_break_naturally_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("block.block_break_naturally_message"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "block_block"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": "#8A2BE2"
    }
]