import i18n from "@/i18n";

export const world_block = [
    {
        "type": "PLAYER_GET_WORLD",
        "tooltip": i18n.t("world.player_get_world_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("world.player_get_world_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "PLAYER"
            }
        ],
        "output": "world",
        "colour": 180
    },
    {
        "type": "WORLD_GET_WORLD",
        "tooltip": i18n.t("world.get_world_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("world.get_world_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "string"
            }
        ],
        "output": "world",
        "colour": 180
    },
    {
        "type": "WORLD_SPAWN_EXPERIENCE",
        "tooltip": i18n.t("world.spawn_experience_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("world.spawn_experience_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "location",
                "check": "location"
            },
            {
                "type": "input_value",
                "name": "NAME",
                "align": "RIGHT",
                "check": "number"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 180
    },
    {
        "type": "WORLD_SPAWN_FIREWORK",
        "tooltip": i18n.t("world.spawn_firework_tooltip"),
        "helpUrl": "",
        "message0": i18n.t("world.spawn_firework_message0"),
        "args0": [
            {
                "type": "input_value",
                "name": "NAME",
                "check": "location"
            }
        ],
        "previousStatement": "flow",
        "nextStatement": "flow",
        "colour": 180
    }



]