import {boss_bar_contents} from "@/data/customize/category/contents/boss_bar_contents";

export const boss_bar_category = [
    {
        "kind": "category",
        "name": "BossBar",
        "colour": "#A55B6D",
        "contents": [
            ...boss_bar_contents
        ]
    }
]