export const data_source_contents = [
    {
        "kind": "block",
        "type": "DATA_SOURCE_PUT_CACHE",
        "name": "将某个数据存放在全局变量缓存中"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_GET_CACHE",
        "name": "从全局变量缓存中拿一个数据"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_REMOVE_CACHE",
        "name": "从全局变量缓存中删除一个数据"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_CONTAINS_KEY_CACHE",
        "name": "数据是否存在于全局变量缓存中"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_PUT_DATA_BASE",
        "name": "将某个数据存放在数据库中"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_GET_DATA_BASE",
        "name": "从数据库中拿一个数据"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_REMOVE_DATA_BASE",
        "name": "从数据库中删除一个数据"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_CONTAINS_KEY_DATABASE",
        "name": "数据是否存在于数据库中"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_GET_STRING_FROM_CONFIG",
        "name": "从配置文件中获取一个字符值"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_GET_LONG_FROM_CONFIG",
        "name": "从配置文件中获取一个整数值"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_GET_DOUBLE_FROM_CONFIG",
        "name": "从配置文件中获取一个小数值"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_GET_BOOLEAN_FROM_CONFIG",
        "name": "从配置文件中获取一个布尔值"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_PUT_STRING_2_CONFIG",
        "name": "往配置文件中存储一个字符值"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_PUT_LONG_2_CONFIG",
        "name": "往配置文件中存储一个整数值"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_PUT_DOUBLE_2_CONFIG",
        "name": "往配置文件中存储一个小数值"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_PUT_BOOLEAN_2_CONFIG",
        "name": "往配置文件中存储一个布尔值"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_GET_STRING_LIST_FROM_CONFIG",
        "name": "从配置文件中获取一个字符集合"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_GET_LONG_LIST_FROM_CONFIG",
        "name": "从配置文件中获取一个整数集合"
    }, {
        "kind": "block",
        "type": "DATA_SOURCE_GET_LONG_LIST_FROM_CONFIG",
        "name": "从配置文件中获取一个小数集合"
    },

]