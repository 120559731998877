import {javascriptGenerator, Order} from "blockly/javascript";

javascriptGenerator.forBlock['TEAM_CREATE_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#createTeam(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_CREATE_TEAM_SCOREBOARD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return [`#createTeam(${value_name},${value_name2})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getTeam(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_TEAM_SCOREBOARD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return [`#createTeam(${value_name},${value_name2})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_PLAYER_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getPlayerTeam(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_PLAYER_TEAM_SCOREBOARD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return [`#getPlayerTeam(${value_name},${value_name2})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_ADD_PLAYER_2_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#addPlayer2Team(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_ADD_PLAYER_2_TEAM_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#addPlayer2Team(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_IS_PLAYER_IN_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#isPlayerInTeam(${value_name},${value_name2})`;
}

javascriptGenerator.forBlock['TEAM_IS_PLAYER_IN_TEAM_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#isPlayerInTeam(${value_name},${value_name2})`;
}

javascriptGenerator.forBlock['TEAM_REMOVE_PLAYER_4_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#removePlayer4Team(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_REMOVE_PLAYER_4_TEAM_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#removePlayer4Team(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_DELETE_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return `#deleteTeam(${value_name});`;
}

javascriptGenerator.forBlock['TEAM_DELETE_TEAM_TEAM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return `#deleteTeam(${value_name});`;
}

javascriptGenerator.forBlock['TEAM_GET_COLOR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getColor(${value_name})`, Order.NONE];
}
javascriptGenerator.forBlock['TEAM_GET_ENTRIES'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getEntries(${value_name})`, Order.NONE];
}
javascriptGenerator.forBlock['TEAM_GET_DISPLAY_NAME'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getDisplayName(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_NAME_TAG_VISIBILITY'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getNameTagVisibility(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_NAME'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getName(${value_name})`, Order.NONE];
}
javascriptGenerator.forBlock['TEAM_GET_SCOREBOARD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getScoreboard(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_SIZE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getSize(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_PLAYER'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getPlayers(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_PREFIX'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getPrefix(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_GET_SUFFIX'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    return [`#getSuffix(${value_name})`, Order.NONE];
}

javascriptGenerator.forBlock['TEAM_SET_ALLOW_FRIENDLY_FIRE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#setAllowFriendlyFire(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_SET_CAN_SEE_FRIENDLY_INVISIBLE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#setCanSeeFriendlyInvisibles(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_SET_COLOR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#setColor(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_SET_DISPLAY_NAME'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#setDisplayName(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_SET_NAME_TAG_VISIBILITY'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#setNameTagVisibility(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_SET_PREFIX'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#setPrefix(${value_name},${value_name2});`;
}

javascriptGenerator.forBlock['TEAM_SET_SUFFIX'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    return `#setSuffix(${value_name},${value_name2});`;
}