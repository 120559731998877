export const block_contents = [
    {
        "kind": "block",
        "type": "BLOCK_GET_BLOCK",
        "name": "通过事件获取方块"
    }, {
        "kind": "block",
        "type": "BLOCK_SET_TYPE",
        "name": "设置方块类型"
    }, {
        "kind": "block",
        "type": "BLOCK_GET_TYPE",
        "name": "获取方块类型"
    }, {
        "kind": "block",
        "type": "BLOCK_GET_BLOCK_LOCATION",
        "name": "通过坐标获取方块"
    }, {
        "kind": "block",
        "type": "BLOCK_BREAK_NATURALLY",
        "name": "破坏方块"
    },
]