import {javascriptGenerator, Order} from "blockly/javascript";

// 两个值判断
javascriptGenerator.forBlock['BASE_SYMBOL'] = function (block, generator) {
    const value_1 = generator.valueToCode(block, '1', Order.NONE);
    const dropdown_4 = block.getFieldValue('4');
    const value_3 = generator.valueToCode(block, '3', Order.NONE);

    const code = `${value_1}${dropdown_4}${value_3}`;
    return [code, Order.NONE];
}

// 数字
javascriptGenerator.forBlock['BASE_NUMBER'] = function (block) {
    const number_number = block.getFieldValue('NUMBER');

    const code = `${number_number}`;
    return [code, Order.NONE];
}

// 字符串
javascriptGenerator.forBlock['BASE_STRING'] = function (block, generator) {
    const text_string_name = block.getFieldValue('STRING_NAME');
    let code = `"${text_string_name.replace(/([\\"])/g, '\\$1')}"`;

    return [code, Order.NONE];
}

// 布尔值
javascriptGenerator.forBlock['BASE_BOOLEAN'] = function (block, generator) {
    const dropdown_boolean = block.getFieldValue('BOOLEAN');

    const code = `${dropdown_boolean}`;
    return [code, Order.NONE];
}

// 转换数字成字符串
javascriptGenerator.forBlock['BASE_TO_STRING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#toString(${value_name})`;
    return [code, Order.NONE];
}

// 拼接字符串
javascriptGenerator.forBlock['BASE_CONCAT_STRING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_name2 = generator.valueToCode(block, 'NAME2', Order.NONE);

    const code = `#concatString(${value_name},${value_name2})`;
    return [code, Order.NONE];
}

// 逻辑取反
javascriptGenerator.forBlock['BASE_NOT'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#not(${value_name})`;
    return [code, Order.NONE];
}

// 生成UUID
javascriptGenerator.forBlock['BASE_GENERATE_UUID'] = function () {

    const code = `#generateUUID()`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_SUB_STRING_1'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);

    const code = `#subString(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_SUB_STRING_2'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);
    const value_start2 = generator.valueToCode(block, 'start2', Order.NONE);

    const code = `#subString(${value_name},${value_start},${value_start2})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_CONTAINS_STRING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);

    const code = `#containsString(${value_name},${value_start})`;
    return [code, Order.NONE];
}
javascriptGenerator.forBlock['BASE_REPLACE_STRING'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);
    const value_start2 = generator.valueToCode(block, 'start2', Order.NONE);

    const code = `#replaceString(${value_name},${value_start},${value_start2})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_INSTANCE_OF'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);

    const code = `#instanceOf(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_STRUCT_TYPE'] = function (block, generator) {
    const dropdown_boolean = block.getFieldValue('BOOLEAN');

    const code = `${dropdown_boolean}`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_GET_STRUCT_TYPE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#getStructType(${value_name})`;
    return [code, Order.NONE];
}
javascriptGenerator.forBlock['BASE_IS_NULL'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#isNull(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_OPERATE'] = function (block, generator) {
    const value_num1 = generator.valueToCode(block, 'NUM1', Order.NONE);
    const dropdown_name = block.getFieldValue('OPERATOR');
    const value_num2 = generator.valueToCode(block, 'NUM2', Order.NONE);

    const code = `${value_num1}${dropdown_name}${value_num2}`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_ABS'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);

    const code = `#abs(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_REMAINDER'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'start', Order.NONE);

    const code = `#remainder(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_RANDOM_NUMBER'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#randomNumber(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_AND_OR'] = function (block, generator) {
    const value_num1 = generator.valueToCode(block, 'NUM1', Order.NONE);
    const dropdown_name = block.getFieldValue('NAME');
    const value_num2 = generator.valueToCode(block, 'NUM2', Order.NONE);

    const code = `(${value_num1}${dropdown_name}${value_num2})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_CREATE_LIST'] = function () {

    const code = `#createList()`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_LIST_ADD'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#listAdd(${value_name},${value_start});`;
    return code;
}

javascriptGenerator.forBlock['BASE_LIST_REMOVE_ELEMENT'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#listRemoveElement(${value_name},${value_start});`;
    return code;
}


javascriptGenerator.forBlock['BASE_LIST_REMOVE_INDEX'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#listRemoveIndex(${value_name},${value_start});`;
    return code;
}

javascriptGenerator.forBlock['BASE_LIST_CONTAINS'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#listContains(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_LIST_GET'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#listGet(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_LIST_IS_EMPTY'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#listIsEmpty(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_LIST_CLEAR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#listClear(${value_name});`;
    return code;
}

javascriptGenerator.forBlock['BASE_LIST_SIZE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#listSize(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_CREATE_MAP'] = function () {

    const code = `#createMap()`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_MAP_PUT_NUM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);
    const value_start2 = generator.valueToCode(block, 'value2', Order.NONE);

    const code = `#mapPut(${value_name},${value_start},${value_start2});`;
    return code;
}

javascriptGenerator.forBlock['BASE_MAP_PUT_STR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);
    const value_start2 = generator.valueToCode(block, 'value2', Order.NONE);

    const code = `#mapPut(${value_name},${value_start},${value_start2});`;
    return code;
}

javascriptGenerator.forBlock['BASE_MAP_REMOVE_NUM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#mapRemove(${value_name},${value_start});`;
    return code;
}

javascriptGenerator.forBlock['BASE_MAP_REMOVE_STR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#mapRemove(${value_name},${value_start});`;
    return code;
}


javascriptGenerator.forBlock['BASE_MAP_GET_NUM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#mapGet(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_MAP_GET_STR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#mapGet(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_MAP_CONTAINS_KEY_NUM'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#mapContainsKey(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_MAP_CONTAINS_KEY_STR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);
    const value_start = generator.valueToCode(block, 'value', Order.NONE);

    const code = `#mapContainsKey(${value_name},${value_start})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_MAP_CLEAR'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#mapClear(${value_name});`;
    return code;
}

javascriptGenerator.forBlock['BASE_MAP_KEY_SET'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#mapKeySet(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_MAP_VALUES'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#mapValues(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_MAP_IS_EMPTY'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#mapIsEmpty(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_MAP_SIZE'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#mapSize(${value_name})`;
    return [code, Order.NONE];
}

javascriptGenerator.forBlock['BASE_SET_PAPI_RESULT'] = function (block, generator) {
    const value_name = generator.valueToCode(block, 'NAME', Order.NONE);

    const code = `#setPapiResult(${value_name})`;
    return code;
}

javascriptGenerator.forBlock['BASE_RETURN_1'] = function (block, generator) {

    const code = `#return();`;
    return code;
}

javascriptGenerator.forBlock['BASE_BREAK'] = function (block, generator) {

    const code = `#break();`;
    return code;
}
javascriptGenerator.forBlock['BASE_CONTINUE'] = function (block, generator) {

    const code = `#continue();`;
    return code;
}